import React from "react";
import {
  matchPath,
  Redirect,
  Route,
  Switch
} from "react-router-dom";

import {CFade} from "@coreui/react";
import {useLocation} from "react-router";
import {useTranslation} from "react-i18next";

const Content = ({routes}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const currentRoute = routes.find(route => matchPath(location.pathname, {
    path: route.path,
    exact: route.exact
  }));

  if (currentRoute) {
    document.title = `YamSender - ${t(currentRoute.name)}`;
  }
  return (
    <Switch>
      {routes.map((route, idx) => {
        return route.component && (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => (
              <CFade>
                <route.component {...props} {...route.props} />
              </CFade>
            )}/>
        );
      })}
      <Redirect from="/" to={routes.find((route) => route.default).path}/>
    </Switch>
  );
};

export default React.memo(Content);