import React, { useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CInput,
  CCardBody,
  CFormGroup,
  CButton,
  CLabel,
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CSelect,
  CSwitch,
} from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { showModalWindow } from "../../../utils/actionWrappers";
import { withErrorBoundary } from "../../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";

const Directory = ({ name, onSave, initialData }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onCancelClick = () => {
    if (isEqual(directory.initialValues, directory.values)) {
      history.push("/directories");
    } else {
      showModalWindow.confirm(
        t("modal:title.confirm", "Confirm your action"),
        t("modal:message.cancel", "Are you sure you want to cancel all changes?"),
        "warning",
        () => history.push("/directories")
      );
    }
  };

  useEffect(() => {
    directory.resetForm(
      {
        values: {
          name: initialData.name || "",
          host: initialData.host || "",
          login: initialData.login || "",
          password: "",
          base_dn: initialData.base_dn || "",
          ldap_user_filter: initialData.ldap_user_filter || "",
          ldap_group_filter: initialData.ldap_group_filter || "",
          use_for_authentication: initialData.use_for_authentication || false,
        },
      },
      [initialData]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const directory = useFormik({
    initialValues: {
      name: "",
      host: "",
      login: "",
      password: "",
      base_dn: "",
      ldap_user_filter: "",
      ldap_group_filter: "",
      use_for_authentication: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required(),
      host: yup.string().required(),
      login: yup.string().required(),
      password: yup.string().required(),
      base_dn: yup.string().required(),
      ldap_user_filter: yup.string().required(),
      ldap_group_filter: yup.string(),
    }),
    onSubmit: (values) => onSave(values),
  });

  return (
    <CCard className="MembersCard">
      <CCardHeader>
        <h1>{name}</h1>
      </CCardHeader>
      <CCardBody>
        <CForm className="col-xl-8 col-lg-9 col-md-10 col-sm-10" id="directory-form" onSubmit={directory.handleSubmit}>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.name", "Name")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                id="name"
                name="name"
                placeholder={t("form:placeholder.directoryName", "Enter directory name")}
                autoComplete="name"
                value={directory.values.name}
                invalid={!!directory.errors.name}
                onChange={directory.handleChange}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.type", "Type")}</CInputGroupText>
              </CInputGroupPrepend>
              <CSelect
                disabled
                value={directory.values.type}
                onChange={directory.handleChange}
                custom
                name="type"
                id="type"
              >
                <option value="OpenLDAP">OpenLDAP</option>
              </CSelect>
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.host", "Host")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                value={directory.values.host}
                onChange={directory.handleChange}
                id="host"
                invalid={!!directory.errors.host}
                name="host"
                autoComplete="host"
                placeholder={t("form:placeholder.host", "Enter host")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.login", "Login")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                value={directory.values.login}
                onChange={directory.handleChange}
                id="login"
                autoComplete="login"
                invalid={!!directory.errors.login}
                name="login"
                placeholder={t("form:placeholder.login", "Enter login")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.password", "Password")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="password"
                value={directory.values.password}
                onChange={directory.handleChange}
                invalid={!!directory.errors.password}
                id="password"
                autoComplete="password"
                name="password"
                placeholder={t("form:placeholder.password", "Enter password")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.baseDn", "Base DN")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                value={directory.values.base_dn}
                onChange={directory.handleChange}
                id="base_dn"
                invalid={!!directory.errors.base_dn}
                autoComplete="base_dn"
                name="base_dn"
                placeholder={t("form:placeholder.baseDn", "Enter base DN")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.ldapUserFilter", "User filter")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                value={directory.values.ldap_user_filter}
                onChange={directory.handleChange}
                id="ldap_user_filter"
                invalid={!!directory.errors.ldap_user_filter}
                autoComplete="ldap_user_filter"
                name="ldap_user_filter"
                placeholder={t("form:placeholder.ldapUserFilter", "Enter user filter")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CInputGroup>
              <CInputGroupPrepend>
                <CInputGroupText>{t("form:label.ldapGroupFilter", "Group filter")}</CInputGroupText>
              </CInputGroupPrepend>
              <CInput
                type="text"
                id="ldap_group_filter"
                value={directory.values.ldap_group_filter}
                onChange={directory.handleChange}
                name="ldap_group_filter"
                autoComplete="ldap_group_filter"
                placeholder={t("form:placeholder.ldapGroupFilter", "Enter group filter")}
              />
            </CInputGroup>
          </CFormGroup>
          <CFormGroup>
            <CSwitch
              size="sm"
              className="mr-3 text-center align-middle"
              color="primary"
              checked={directory.values.use_for_authentication}
              onChange={directory.handleChange}
              name="use_for_authentication"
              id="use_for_authentication"
              shape="pill"
              variant="3d"
            />
            <CLabel className="mb-0" htmlFor="use_for_authentication">
              {t("form:label.useForAuthentication", "Use for authentication")}
            </CLabel>
          </CFormGroup>
          <CFormGroup>
            <CButton
              onClick={onCancelClick}
              type="reset"
              form="directory-form"
              color="warning"
              variant="outline"
              className="mr-3"
            >
              {t("form:button.cancel", "Cancel")}
            </CButton>
            <CButton
              type="submit"
              form="directory-form"
              color="success"
              variant="outline"
            >
              {t("form:button.save", "Save")}
            </CButton>
          </CFormGroup>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(Directory);
