import React, { useState } from "react";
import {
  CButton,
  CRow,
  CImg,
  CModal,
  CCol,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CLabel,
  CInputFile,
} from "@coreui/react";
import { updateProfileAction } from "../../store/actions/auth";
import { changeCurrentUserAvatarRequest } from "../../services/api";
import { useActions } from "../../store/actions";
import { showNotification } from "../../utils/actionWrappers";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";

const ChangeAvatarModalWindow = ({ onHide, avatarPath = "/avatars/blank-avatar.jpg" }) => {
  const updateProfile = useActions(updateProfileAction);
  const { t } = useTranslation();

  const [image, setImage] = useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleClick = (e) => {
    changeCurrentUserAvatarRequest(image.raw).then(({ data }) => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.userImage", "Photo has been updated!", {}),
        "success"
      );
      updateProfile(data.first_name, data.last_name, data.avatar);
    });
    onHide();
  };

  return (
    <CModal centered onClose={onHide} color="info" show>
      <CModalHeader closeButton>{t("modal:title.uploadPhoto", "Upload new photo")}</CModalHeader>
      <CModalBody>
        <CRow className="justify-content-center">
          <CCol className="d-flex justify-content-center">
            <CImg src={image.preview || avatarPath} className="profile__avatar" alt="Avatar" />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CLabel className="btn btn-info">
          <CInputFile style={{ display: "none" }} onChange={handleChange} id="addPhoto" className="" type="file" />
          {t("form:button.browse", "Browse")}
        </CLabel>
        <div>
          <CButton className="mr-4" onClick={onHide} color="info">
            {t("form:button.cancel", "Cancel")}
          </CButton>
          <CButton disabled={!image.raw} onClick={handleClick} color="info">
            {t("form:button.save", "Save")}
          </CButton>
        </div>
      </CModalFooter>
    </CModal>
  );
};

export default withErrorBoundary(ChangeAvatarModalWindow);
