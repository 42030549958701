import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CRow, CAlert,
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import "./LoginSub.scss";
import {checkValidCharactersForSubdomain, checkValidSubdomain} from "../../utils";
import {getTenantInfoRequest} from "../../services/api";
import {DOMAIN, PORT, PROTOCOL} from "../../utils/constants";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {Trans, useTranslation} from "react-i18next";

const LoginSub = () => {
  const [subdomain, setSubdomain] = useState("");
  const [nonExistentSubdomain, setNonExistentSubdomain] = useState("");
  const {t} = useTranslation();

  const onSubdomainChange = (event) => {
    if (checkValidCharactersForSubdomain(event.target.value)) {
      setSubdomain(event.target.value.toLowerCase());
    }
  };
  const isValidSubdomain = checkValidSubdomain(subdomain);

  const onLoginSubFormSubmit = (event) => {
    event.preventDefault();
    getTenantInfoRequest(`${subdomain}.${DOMAIN}`).then((result) => {
      if (!result.isExist) {
        setNonExistentSubdomain(subdomain);
      } else {
        window.location.assign(`${PROTOCOL}//${subdomain}.${DOMAIN}${PORT ? `:${PORT}` : ""}/login`);
      }
    });
  };

  return (
    <>
      <ARIcon name={"cilBolt"} size={"3xl"} />
      <CForm onSubmit={onLoginSubFormSubmit}>
        <h1>{t("card.title.loginSubdomain", "Login by subdomain")}</h1>
        <p className="text-muted">{t("card.description.loginSubdomain", "Sign In to your subdomain")}</p>
        <CAlert
          className="LoginSub__non-existent-alert"
          color="danger"
          show={!!nonExistentSubdomain && nonExistentSubdomain === subdomain}
        >
          <p className="LoginSub__non-existent-alert__text">
            <Trans
              i18nKey="notification:message.error.domain.nonExistent"
              values={{ domain: `${nonExistentSubdomain}.${DOMAIN}` }}
            >
              The domain{" "}
              <b>
                {nonExistentSubdomain}.{DOMAIN}
              </b>{" "}
              does not exist. But you can
              <CButton
                className="LoginSub__non-existent-alert__register"
                tag={Link}
                to={`/sign_up?subdomain=${nonExistentSubdomain}`}
                color="danger"
              >
                Register
              </CButton>{" "}
              this domain for your company!
            </Trans>
          </p>
        </CAlert>
        <CInputGroup className="mb-3">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilLink"} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="text"
            className="LoginSub__subdomain-field"
            placeholder={t("form:placeholder.subdomain", "subdomain")}
            autoComplete="your-company-name"
            value={subdomain}
            onChange={onSubdomainChange}
            autoFocus
          />
          <CInputGroupAppend>
            <CInputGroupText>{`.${DOMAIN}`}</CInputGroupText>
          </CInputGroupAppend>
        </CInputGroup>
        <CRow>
          <CCol xs="6">
            <CButton type="submit" color="primary" className="px-4" disabled={!isValidSubdomain}>
              {t("button.login", "Login")}
            </CButton>
          </CCol>
          <CCol xs="6" className="text-right">
            <CButton tag={Link} to="/sign_up" color="link" className="px-0">
              {t("button.register", "Sign Up")}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default withErrorBoundary(LoginSub);