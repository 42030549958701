import React from "react";
import {CCard, CCardBody} from "@coreui/react";


const FallbackStub = () => {
  return (
    <CCard>
      <CCardBody>
        <div className="text-center">Unfortunately, an error has occurred in this component.</div>
      </CCardBody>
    </CCard>
  );
};

export default FallbackStub;