import { AUTH } from "../actions/types";
import Cookies from "js-cookie";

const initialState = {
  email: localStorage.getItem("email") || "",
  isAuthorized: !!Cookies.get("token"),
  isStaff: localStorage.getItem("isStaff") === "true",
  error: null,
  profile: {
    firstName: localStorage.getItem("firstName") || "",
    lastName: localStorage.getItem("lastName") || "",
    avatarPath: localStorage.getItem("avatarPath") || "/avatars/blank-avatar.jpg",
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.LOGIN_SUCCESS:
    case AUTH.SIGN_UP_SUCCESS:
      localStorage.setItem("email", action.payload.email);
      localStorage.setItem("isStaff", action.payload.isStaff);

      const cookieOptions = {
        secure: process.env.NODE_ENV === "production",
      };

      if (action.payload.isRememberMe) {
        cookieOptions.expires = 365;
      }

      Cookies.set("token", action.payload.token, cookieOptions);

      return {
        ...state,
        email: action.payload.email,
        isStaff: action.payload.isStaff,
        isAuthorized: true,
        error: null,
      };
    case AUTH.LOGOUT_SUCCESS:
    case AUTH.LOGIN_FAILURE:
    case AUTH.SIGN_UP_FAILURE:
      localStorage.setItem("email", "");
      localStorage.setItem("isStaff", false);
      Cookies.remove("token");
      return {
        ...state,
        token: "",
        email: "",
        isAuthorized: false,
        error: action.payload.error,
        isStaff: false
      };
    case AUTH.UPDATE_PROFILE:
      localStorage.setItem("firstName", action.payload.firstName);
      localStorage.setItem("lastName", action.payload.lastName);
      localStorage.setItem("avatarPath", action.payload.avatar);
      return {
        ...state,
        profile: {
          ...state.profile,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          avatarPath: action.payload.avatar,
        }
      };
    default:
      return state;
  }
};

export default authReducer;