import i18next from "../../../services/language/i18next";

const getTableColumnHeaders = () => [
  { key: "name", label: i18next.t("table:header.senderName", "Sender name"), _style: { width: "20%" } },
  { key: "email", label: i18next.t("table:header.email", "Email"), _style: { width: "20%" } },
  { key: "edit", label: "", _style: { width: "10%" }, sorter: false, filter: false },
  { key: "remove", label: "", _style: { width: "10%" }, sorter: false, filter: false },
];

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [10, 50, 100],
});

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label"),
  placeholder: i18next.t("table:filter.placeholder.senders", "Search for senders"),
  external: true,
});

export { getTableColumnHeaders, getTableFilterText, getItemsPerPageSelectValues };
