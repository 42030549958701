import React, { useMemo } from "react";
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { useTranslation } from "react-i18next";
import ARFlag from "../../../../ARFlag";
import "./HeaderLanguageDropdown.scss";
import { withErrorBoundary } from "../../../../ErrorBoundary";

const HeaderLanguageDropdown = (props) => {
  const { i18n } = useTranslation();
  const onLanguageClick = (language) => {
    i18n.changeLanguage(language);
  };

  const allLanguages = useMemo(() => {
    return process.env.NODE_ENV === "development"
      ? i18n.options.supportedLngs
      : i18n.options.supportedLngs.slice(0, -1);
  }, [i18n.options.supportedLngs]);

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret>
        <ARFlag country={i18n.language} className="mr-2" />
        <span className="HeaderLanguageDropdown__short-language">{i18n.language.toUpperCase()}</span>
      </CDropdownToggle>
      <CDropdownMenu className="HeaderLanguageDropdown__dropdown-menu" placement="bottom">
        <CDropdownItem className="HeaderLanguageDropdown__dropdown-item" key={i18n.language}>
          <ARFlag country={i18n.language} className="mr-2" />
          <span className="HeaderLanguageDropdown__short-language">{i18n.language.toUpperCase()}</span>
        </CDropdownItem>
        {allLanguages.map(
          (langKey) =>
            langKey !== i18n.language && (
              <CDropdownItem
                className="HeaderLanguageDropdown__dropdown-item"
                onClick={onLanguageClick.bind(null, langKey)}
                key={langKey}
              >
                <ARFlag country={langKey} className="mr-2" />
                <span className="HeaderLanguageDropdown__short-language">{langKey.toUpperCase()}</span>
              </CDropdownItem>
            )
        )}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default withErrorBoundary(HeaderLanguageDropdown);
