import React, { useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CBreadcrumb, CBreadcrumbItem, CRow } from "@coreui/react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import AudienceChangeNameFrom from "./AudienceChangeNameFrom";
import AudienceAddSubscriptionsForm from "./AudienceAddSubscriptionsForm";
import ARIcon from "../../components/ARIcon";
import AudienceTable from "./AudienceTable.jsx";
import { readAudienceRequest } from "../../services/api/index.js";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { showNotification } from "../../utils/actionWrappers";
import { useTranslation } from "react-i18next";
import "./AudienceExact.scss";

const AudienceExact = () => {
  const location = useLocation();
  const [audienceId] = location.pathname.split("/").splice(-1);
  const [audience, setAudience] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    readAudienceRequest(audienceId).then(({ data }) => {
      setAudience(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!audience) {
    return (
      <CCardHeader>
        <h1>No info</h1>
      </CCardHeader>
    );
  }

  const { name, addresses, bcc_name } = audience;

  const copyBbcEmailToClipboard = () => {
    navigator.clipboard.writeText(`${bcc_name}@cp.yamsender.com`);
    showNotification(
      t("notification:title.success", "Success!"),
      t("notification:message.bccToClipboard", "The bcc email was copied to clipboard."),
      "success"
    );
  };

  return (
    <CCard>
      <CCardHeader>
        <h1>{t("page.audienceDetails", "Audience Details")}</h1>
      </CCardHeader>
      <CBreadcrumb>
        <CBreadcrumbItem>
          <Link to={"/audience/"}>{t("page.audiences", "Audiences")}</Link>
        </CBreadcrumbItem>
        <CBreadcrumbItem active>{name}</CBreadcrumbItem>
      </CBreadcrumb>
      <CCardBody>
        <AudienceChangeNameFrom audience={audience} setAudience={setAudience} />
        <CRow className="mb-2">
          <CCol xs="6" lg="3">
            <p className="lead">
              {t("text.audienceDetails.subscribers", "Audience Details", { subscribers: addresses.length })}
            </p>
          </CCol>
          <CCol xs="6" lg="3" className="d-flex align-items-center">
            <p className="lead mr-2 mb-0">
              {t("text.audienceDetails.bccEmail", "BCC email:")} {`${bcc_name}@cp.yamsender.com`}
            </p>
            <span className="audienceExact__copyBtn " onClick={copyBbcEmailToClipboard}>
              <ARIcon name={"cilCopy"} size={"xl"} />
            </span>
          </CCol>
        </CRow>
        <AudienceAddSubscriptionsForm audience={audience} setAudience={setAudience} />
        <AudienceTable audience={audience} setAudience={setAudience} />
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(AudienceExact);
