import {MODAL_WINDOW} from "./types";

export function showModalWindowAction(title, message, color = "info", type = "alert", callbackOK = null, callbackCancel = null) {
  return {
    type: MODAL_WINDOW.SHOW,
    payload: {
      title,
      message,
      type,
      color,
      callbackOK,
      callbackCancel
    }
  };
}

export function hideModalWindowAction() {
  return {
    type: MODAL_WINDOW.HIDE,
  };
}