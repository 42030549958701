import React from "react";
import { CCardBody, CCard, CCardHeader } from "@coreui/react";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <>
      <CCard>
        <CCardHeader>
          <h1>{t("page.faq", "FAQ")}</h1>
        </CCardHeader>
        <CCardBody>
          <h3 className="mb-3">
            {t("faq:title", "Yamsender - mailing service with schedule planning and automatic letters sending.")}
          </h3>
          <h4 className="mb-3">{t("faq:subtitle", "A brief description of creating a mailing:")}</h4>
          <h5>{t("faq:audiences.title", "Audiences")}</h5>
          <ol>
            <li>{t("faq:audiences.items.01", "To create a new audience, go to the tab \"Audiences\".")}</li>
            <li>{t("faq:audiences.items.02", "Enter the name of the new audience, click the button \"Add\".")}</li>
            <li>{t("faq:audiences.items.03", "Add subscribers manually or download them from csv or xlsx files.")}</li>
            <li>
              {t(
                "faq:audiences.items.04",
                "To add subscribers from csv or xlsx files, press the button \"Выберете файл\", select the file, press the \"Upload\" button."
              )}
            </li>
          </ol>
          <h5>{t("faq:campaigns.title", "Campaigns")}</h5>
          <ol start="5">
            <li>{t("faq:campaigns.items.01", "To create a new campaign, go to the tab \"Campaigns\".")}</li>
            <li>{t("faq:campaigns.items.02", "Enter the name of the new campaign, click the button \"Create\".")}</li>
            <li>
              {t(
                "faq:campaigns.items.03",
                "Fill in the required fields in the campaign card, push the button \"Fetching templates\" to get templates, choose it."
              )}
            </li>
            <li>
              {t(
                "faq:campaigns.items.04",
                "Available options to select the type of campaign: \"Send immediately\", \"Start sending at\", \"Automatic mailing\"."
              )}
              <ul>
                <li>{t("faq:campaigns.items.05", "Send immediately - mailing specified audience immediately")}</li>
                <li>{t("faq:campaigns.items.06", "Start sending at - mailing specified audience at the set time")}</li>
                <li>
                  {t(
                    "faq:campaigns.items.07",
                    "Automatic mailing - triggers and sends an email to the subscriber when the subscriber is added to the specified audience"
                  )}
                </li>
              </ul>
            </li>
            <li>{t("faq:campaigns.items.08", "Save campaign draft is also available.")}</li>
            <li>
              {t(
                "faq:campaigns.items.09",
                "Campaign statistics can be viewed by clicking on the \"Statistics\" button in the list of campaigns or by opening the corresponding tab in the campaign card."
              )}
            </li>
          </ol>
          <h5>{t("faq:members.title", "Members")}</h5>
          <ul>
            <li>
              {t(
                "faq:members.items.01",
                "Changing the password, editing and changing the profile avatar are available by clicking in the profile settings in the upper right corner."
              )}
            </li>
          </ul>
          <h5>{t("faq:directories.title", "Directories")}</h5>
          <ul>
            <li>
              {t(
                "faq:directories.items.01",
                "A user with administrator rights can add users by loading the ldap directory."
              )}
            </li>
            <li>
              {t(
                "faq:directories.items.02",
                "To add users from the catalog, go to the tab \"Directories\", push \"Add Directory\", fill in the fields, push \"Save\" button."
              )}
            </li>
            <li>
              {t(
                "faq:directories.items.03",
                "To provide ability to authorize for a user from the directory, checkbox \"Use for authentication\" in directory settings must be enabled."
              )}
            </li>
          </ul>
        </CCardBody>
      </CCard>
    </>
  );
};

export default FAQ;
