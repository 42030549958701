import React, { useEffect, useState } from "react";
import "./Directories.scss";
import { CButton, CCard, CCardBody, CCardHeader, CListGroup, CJumbotron } from "@coreui/react";
import { Link } from "react-router-dom";
import { getMemberDirectoryRequest } from "../../services/api";
import DirectoryCard from "./DirectoryCard/DirectoryCard";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { checkForClearInterval } from "./Directories.model";
import { useTranslation } from "react-i18next";

const Directories = () => {
  const [directoriesInterval, setDirectoriesInterval] = useState(null);
  const [directoriesData, setDirectoriesData] = useState([]);
  const { t } = useTranslation();

  const updateDirectoriesAndHandleInterval = (intervalId) => {
    getMemberDirectoryRequest().then(({ data }) => {
      setDirectoriesData(data);
      checkForClearInterval(data, intervalId);
    });
  };

  const getDirectories = () => {
    getMemberDirectoryRequest().then(({ data }) => setDirectoriesData(data));
  };

  useEffect(() => {
    getMemberDirectoryRequest().then(({ data }) => setDirectoriesData(data));
  }, []);

  return (
    <CCard className="Directories">
      <CCardHeader>
        <h1>{t("page.directories", "Directories")}</h1>
      </CCardHeader>
      <CCardBody>
        <CCard>
          <CCardHeader className="d-flex flex-row Directories__directories-header">
            <h3>{t("card.title.directory.your", "Your user directories")}</h3>
            <CButton
              tag={Link}
              to="/directories/add"
              color="primary"
              className="Directories__add-button"
            >
              {t("button.addDirectory", "Add Directory")}
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CListGroup accent className="Directories__list-group">
              {directoriesData.length === 0 ? (
                <CJumbotron>
                  <h3 className="text-center">
                    {t("emptyList.directories", "There will be a list of your directories here", {})}
                  </h3>
                </CJumbotron>
              ) : (
                directoriesData.map((item, index) => (
                  <DirectoryCard
                    directoriesInterval={directoriesInterval}
                    setDirectoriesInterval={setDirectoriesInterval}
                    onDeleteDirectorySuccess={getDirectories}
                    updateDirectoriesAndHandleInterval={updateDirectoriesAndHandleInterval}
                    key={`${item.name}__${index}`}
                    directoryData={item}
                  />
                ))
              )}
            </CListGroup>
          </CCardBody>
        </CCard>
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(Directories);
