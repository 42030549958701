import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CLabel,
  CFormGroup,
  CInputCheckbox,
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import {loginAction} from "../../store/actions/auth";
import {useActions} from "../../store/actions";
import {useQuery} from "../../utils/useQuery";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {useTranslation} from "react-i18next";

const emailInitialState = {
  value: "",
  isValid: null
};

const passwordInitialState = {
  value: "",
  isValid: null
};

const Login = () => {
  const {t} = useTranslation();
  const [credentials, setCredentials] = useState(
    {
      email: emailInitialState,
      password: passwordInitialState
    }
  );
  const [errorText, setErrorText] = useState("");
  const login = useActions(loginAction);
  const query = useQuery();
  const redirect = query.get("redirect");
  const history = useHistory();
  const [isRememberMe, setIsRememberMe] = useState(false);

  const onCredentialsChange = (event) => {
    setCredentials((oldState) => ({
      ...oldState,
      [event.target.name]: {
        value: event.target.value,
        isValid: event.target.validity.valid
      }
    }));
    setErrorText("");
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    login(credentials.email.value, credentials.password.value, isRememberMe).then(() => {
      history.push(redirect ? redirect : "/checks");
    }).catch((error) => {
      if (error.response && error.response.status === 500) {
        setCredentials({
          email: emailInitialState,
          password: passwordInitialState
        });
        setErrorText(t("notification:message.error.505", "Unfortunately the server is currently unavailable. Try later."));
      } else if (error.response && error.response.status === 400) {
        setErrorText(t("notification:message.error.credentials", "Wrong email or password!"));
        setCredentials((oldState) => ({
          ...oldState,
          password: passwordInitialState
        }));
      } else {
        setErrorText(error.message);
      }
    });
  };

  const onRememberMeChange = ({target}) => {
    setIsRememberMe(target.checked);
  };

  return (
    <>
      <ARIcon name={"cilBolt"} size={"3xl"}/>
      <CForm onSubmit={onFormSubmit} autoComplete="on">
        <h1>{t("card.title.login", "Login")}</h1>
        <CAlert color="danger" show={!!errorText}>
          {errorText}
        </CAlert>
        <p className="text-muted d-flex justify-content-between">
          <span>{t("card.description.login", "Sign In to your account")}</span>
          <CButton tag={Link} to="/forgot_password" color="link"
                   className="p-0">
           {t("button.forgotPassword", "Forgot password?")}
          </CButton>
        </p>
        <CInputGroup className="mb-3">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilUser"}/>
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="email"
            name="email"
            id="email"
            placeholder={t("form:label.email", "Email")}
            autoComplete="email"
            value={credentials.email.value}
            onChange={onCredentialsChange}
            invalid={credentials.email.isValid === false}
            autoFocus
            required
          />
        </CInputGroup>
        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilLockLocked"}/>
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput type="password"
                  placeholder={t("form:label.password", "Password")}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  value={credentials.password.value}
                  onInput={onCredentialsChange}
                  invalid={credentials.password.isValid === false}
                  required
          />

        </CInputGroup>
        <CFormGroup className="ml-4 checkbox">
        <CInputCheckbox
          id="remember_me"
          checked={isRememberMe}
          onChange={onRememberMeChange}
        />
        <CLabel
          htmlFor="remember_me"
          className="mb-0 align-middle"
        >
          {t("form:label.rememberMe", "Remember me")}
        </CLabel>
      </CFormGroup>
        <CRow>
          <CCol xs="6">
            <CButton type="submit" color="primary"
                     disabled={!credentials.email.isValid || !credentials.password.isValid}
                     className="px-4">
                        {t("button.login", "Login")}
                     </CButton>
          </CCol>
          <CCol xs="6" className="text-right">
            <CButton tag={Link}
                     to="/sign_up_user"
                     color="link"
                     className="px-0">
                {t("button.register", "Sign Up")}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};


export default withErrorBoundary(Login);