import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { readMemberDirectoryRequest, updateMemberDirectoryRequest } from "../../../../services/api";
import { showNotification } from "../../../../utils/actionWrappers";
import Directory from "../Directory";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";

const DirectoryEdit = () => {
  const [directoryData, setDirectoryData] = useState({});
  const history = useHistory();
  const { id } = useParams();
  const [directoryName, setDirectoryName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    readMemberDirectoryRequest(id).then(({ data }) => {
      setDirectoryName(data.name);
      setDirectoryData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDirectory = (values) => {
    updateMemberDirectoryRequest(id, values).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.directory", " Directory has been successfully changed"),
        "success"
      );
      history.push("/directories");
    });
  };

  return <Directory name={directoryName} onSave={updateDirectory} initialData={directoryData} />;
};

export default withErrorBoundary(DirectoryEdit);
