import React from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CFormGroup,
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import { restorePasswordRequest } from "../../services/api";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  let [email, setEmail] = React.useState("");
  const { t } = useTranslation();

  const onEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    restorePasswordRequest(email);
  };

  return (
    <>
      <ARIcon name={"cilBolt"} size={"3xl"} />

      <p className="text-muted">
        {t("card.description.restorePassword.forgot", "New password will be sent to your email address")}
      </p>
      <CForm className="mt-4" onSubmit={onFormSubmit}>
        <CInputGroup className="mb-3">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilUser"} />
            </CInputGroupText>
          </CInputGroupPrepend>

          <CInput type="email" required value={email} onInput={onEmailInput} placeholder="Email" autoComplete="email" />
        </CInputGroup>

        <CFormGroup>
          <CButton className="w-100" type="submit" color="primary">
            {t("button.send", "Send")}
          </CButton>
        </CFormGroup>

        <CFormGroup className="w-100 text-center" row>
          <CCol>
            <Link to="/login">{t("button.login", "Sign In")}</Link>
          </CCol>
          <CCol>
            <Link to="/sign_up_user">{t("button.registerNewAccount", "Create a new account")}</Link>
          </CCol>
        </CFormGroup>
      </CForm>
    </>
  );
};

export default withErrorBoundary(ForgotPassword);
