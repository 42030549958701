import React, { useState, useEffect } from "react";

import { CCol, CRow } from "@coreui/react";
import AddSenderForm from "./AddSenderForm";
import SendersTable from "./SendersTable";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { getSendersRequest } from "../../../services/api/index";

const Settings = () => {
  const [senders, setSenders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSendersRequest();
      setSenders(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <AddSenderForm setSenders={setSenders} />
      <CRow className="pt-2">
        <CCol>
          <SendersTable setSenders={setSenders} senders={senders} />
        </CCol>
      </CRow>
    </>
  );
};

export default withErrorBoundary(Settings);
