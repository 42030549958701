import React, { useMemo } from "react";
import { CHeader, CToggler, CHeaderBrand, CHeaderNav, CSubheader, CBreadcrumbRouter } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import HeaderProfileDropdown from "./HeaderProfileDropdown";
import HeaderLanguageDropdown from "./HeaderLanguageDropdown";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const toggleSidebar = () => {
    const value = [true, "responsive"].includes(props.showSidebar) ? false : "responsive";
    props.setShowSidebar(value);
  };

  const toggleSidebarMobile = () => {
    const value = [false, "responsive"].includes(props.showSidebar) ? true : "responsive";
    props.setShowSidebar(value);
  };

  const { t, i18n } = useTranslation();

  const routes = useMemo(
    () =>
      props.routes.map((route) => {
        return { ...route, name: t(route.name) };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.routes, i18n.language]
    );

  return (
    <CHeader withSubheader>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="ml-auto px-3">
        <HeaderLanguageDropdown />
        <HeaderProfileDropdown />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} />
      </CSubheader>
    </CHeader>
  );
};

export default Header;
