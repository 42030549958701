import React, {useEffect, useState} from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInvalidFeedback,
  CLabel,
  CRow,
  CImg,
} from "@coreui/react";
import {getProfileInfoRequest, saveProfileInfoRequest} from "../../services/api";
import {showNotification} from "../../utils/actionWrappers";
import {useActions} from "../../store/actions";
import {updateProfileAction} from "../../store/actions/auth";
import {emailCharactersPattern, emailValidation} from "../../utils";
import UserPhotoModal from "./UserPhotoModal";
import {useSelector} from "react-redux";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation, Trans } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const avatarPath = useSelector(state => state.auth.profile.avatarPath);
  const updateProfile = useActions(updateProfileAction);
  const [showUserPhotoModal, setShowUserPhotoModal] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    first_name: {
      value: "",
      isValid: null
    },
    last_name: {
      value: "",
      isValid: null
    },
    email: {
      value: "",
      isValid: null
    },
  });
  const [password, setPassword] = useState({
    new_password: "",
    confirm_password: "",
    isValid: null
  });

  const onProfileInfoChange = (event) => {
    if (!event.target.validity.patternMismatch) {
      setProfileInfo((oldState) => ({
        ...oldState,
        [event.target.name]: {
          ...oldState[event.target.name],
          value: event.target.value,
          isValid: event.target.validity.valid &&
            (event.target.name !== "email" || emailValidation.test(event.target.value))
        }
      }));
    }
  };

  const onPasswordChange = (event) => {
    setPassword((oldState) => {
      const newPasswordState = {
        ...oldState,
        [event.target.name]: event.target.value,
      };
      newPasswordState.isValid = event.target.name === "confirm_password" ?
        newPasswordState.new_password === newPasswordState.confirm_password
        && !!newPasswordState.new_password : null;
      return newPasswordState;
    });
  };

  const onProfileFormSubmit = (event) => {
    event.preventDefault();
    const newProfileInfo = {};

    Object.keys(profileInfo).forEach((fieldName) => newProfileInfo[fieldName] = profileInfo[fieldName].value);

    if (password.isValid) {
      newProfileInfo.password = password.new_password;
    }

    saveProfileInfoRequest(newProfileInfo).then(({ data }) => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.profile", "Profile has been updated."),
        "success"
      );
      updateProfile(newProfileInfo.first_name, newProfileInfo.last_name, data.avatar);
    });
  };

  useEffect(() => {
    getProfileInfoRequest().then((result) => {
      const newProfileInfo = {};
      for (let name of Object.keys(result.data)) {
        newProfileInfo[name] = {
          value: result.data[name],
          isValid: true
        };
      }
      delete newProfileInfo.avatar;
      delete newProfileInfo.id;

      setProfileInfo((oldState) => ({
        ...oldState,
        ...newProfileInfo
      }));
    });
  }, []);

  const modalToggle = () => {
    setShowUserPhotoModal(!showUserPhotoModal);
  };

  const addNewPhotoHandler = () => {
    modalToggle();
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <h1>{t("card.title.profile", "Profile")}</h1>
        </CCardHeader>
        <CCardBody>
          <CRow className="mb-3">
            <CCol>
              <CImg src={avatarPath} className="profile__avatar " alt="Photo" />
            </CCol>
            <CCol className="d-flex align-items-center" xs="12" md="9">
              <CButton onClick={addNewPhotoHandler} className="mr-3" color="info">
                {t("form:button.uploadAvatar", "Upload user image")}
              </CButton>
            </CCol>
          </CRow>
          <CForm id="profile-form" className="form-horizontal" onSubmit={onProfileFormSubmit}>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="fist_name">{t("form:label.firstName", "First name")}</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  id="first_name"
                  name="first_name"
                  placeholder={t("form:placeholder.firstName", "Enter first name")}
                  value={profileInfo.first_name.value || ""}
                  pattern=".*"
                  valid={profileInfo.first_name.isValid}
                  invalid={profileInfo.first_name.isValid === false}
                  required
                  onChange={onProfileInfoChange}
                />
                <CInvalidFeedback>
                  {t("form:error.required", "This field is required!")}
                </CInvalidFeedback>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="last_name">{t("form:label.lastName", "Last name")}</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  id="last_name"
                  name="last_name"
                  placeholder={t("form:placeholder.lastName", "Enter last name")}
                  value={profileInfo.last_name.value || ""}
                  pattern=".*"
                  valid={profileInfo.last_name.isValid}
                  invalid={profileInfo.last_name.isValid === false}
                  required
                  onChange={onProfileInfoChange}
                />
                <CInvalidFeedback>
                  {t("form:error.required", "This field is required!")}
                </CInvalidFeedback>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="email">{t("form:label.email", "Email")}</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  type="text"
                  id="email"
                  name="email"
                  placeholder={t("form:placeholder.email", "Enter email")}
                  autoComplete="email"
                  value={profileInfo.email.value}
                  onChange={onProfileInfoChange}
                  pattern={emailCharactersPattern}
                  valid={profileInfo.email.isValid}
                  invalid={profileInfo.email.isValid === false}
                  required
                />
                <CInvalidFeedback>
                  <Trans i18nKey="form:error.email.value">
                    You must enter your email in the format <b>user@domain.com</b>!
                  </Trans>
                </CInvalidFeedback>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="password">{t("form:label.password_new", "New password")}</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder={t("form:placeholder.password_new", "Enter new password")}
                  autoComplete="off"
                  value={password.new_password}
                  valid={password.isValid}
                  invalid={password.isValid === false}
                  onChange={onPasswordChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel htmlFor="confirm-password">{t("form:label.password_newConfirm", "Confirm new password")}</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CInput
                  type="password"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder={t("form:label.password_newConfirm", "Confirm new password")}
                  autoComplete="off"
                  value={password.confirm_password}
                  valid={password.isValid}
                  invalid={password.isValid === false}
                  onChange={onPasswordChange}
                />
                <CInvalidFeedback>{t("form:error.password.match", "Passwords must match!")}</CInvalidFeedback>
              </CCol>
            </CFormGroup>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            type="submit"
            form="profile-form"
            color="success"
            variant="outline"
            disabled={Object.values(profileInfo).reduce(
              (previousValue, currentValue) => previousValue || !currentValue.isValid,
              false
            )}
          >
            {t("form:button.save", "Save")}
          </CButton>
        </CCardFooter>
      </CCard>
      {showUserPhotoModal && <UserPhotoModal avatarPath={avatarPath} onHide={modalToggle}></UserPhotoModal>}
    </>
  );
};

export default withErrorBoundary(Profile);
