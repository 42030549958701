import React, {useState} from "react";

import Content from "../Content";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {CContainer} from "@coreui/react";
import routeNetwork from "../../../routes";
import {useSelector} from "react-redux";

const AuthorizedPageWrapper = (props) => {
  const [showSidebar, setShowSidebar] = useState("responsive");
  const state = useSelector(state => state);
  const routes = routeNetwork.getRoutes(state);

  return (
    <div className="c-app c-default-layout">
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="c-wrapper">
        <Header
          routes={routes}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <Content routes={routes}/>
            </CContainer>
          </main>
        </div>
        {/*<Footer/>*/}
      </div>
    </div>
  );
};

export default AuthorizedPageWrapper;