import { combineReducers } from "redux";
import authReducer from "./auth";
import notificationReducer from "./notification";
import globalReducer from "./global";
import modalReducer from "./modal";

export const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  global: globalReducer,
  modal: modalReducer
});
