import deployedConfig from "./deployed";
import localConfig from "./local";
import defaultConfig from "./default";

function getConfig() {
  switch (process.env.REACT_APP_SERVER_LOCATION) {
    case "local":
      return localConfig;
    case "deployed":
      return deployedConfig;
    default:
      return defaultConfig;
  }
}

export default getConfig();