import {authRequest, getProfileInfoRequest, logoutRequest} from "../../services/api";
import {AUTH} from "./types";

export function loginAction(email, password, isRememberMe) {
  return async dispatch => {
    try {
      const authResponse = await authRequest(email, password);
      dispatch({
        type: AUTH.LOGIN_SUCCESS,
        payload: {
          token: authResponse.data.token,
          isStaff: authResponse.data.is_staff,
          email,
          isRememberMe
        },
      });

      const profileResponse = await getProfileInfoRequest();
      dispatch(updateProfileAction(profileResponse.data.first_name || profileResponse.data.email,
        profileResponse.data.last_name || "", profileResponse.data.avatar));

    } catch (e) {
      dispatch({
        type: AUTH.LOGIN_FAILURE,
        payload: {
          error: e
        }
      });
      throw e;
    }
  };
}

export function logoutAction(withoutRequest = false) {
  return async dispatch => {
    if (!withoutRequest) {
      await logoutRequest();
    }

    dispatch({
      type: AUTH.LOGOUT_SUCCESS,
      payload: {
        error: null
      }
    });
  };
}

export function updateProfileAction(firstName, lastName, avatar) {
  return {
    type: AUTH.UPDATE_PROFILE,
    payload: {
      firstName,
      lastName,
      avatar,
    }
  };
}