import i18next from "../../services/language/i18next";

const getTableColumnHeaders = () => [
  { key: "first_name", label: i18next.t("table:header.firstName", "First Name"), _style: { width: "20%" } },
  { key: "last_name", label: i18next.t("table:header.lastName", "Last Name"), _style: { width: "20%" } },
  { key: "email", label: i18next.t("table:header.email", "Email"), _style: { width: "20%" } },
  { key: "member_directory", label: i18next.t("table:header.directory", "Directory"), _style: { width: "20%" } },
];

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [10, 50, 100],
});

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label", "Search:"),
  placeholder: i18next.t("table:filter.placeholder.members", "Search for members"),
  external: true,
});

export { getTableColumnHeaders, getItemsPerPageSelectValues, getTableFilterText };
