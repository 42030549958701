import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CInput,
  CRow,
  CCardTitle,
  CFormGroup,
  CForm,
  CLabel,
  CInvalidFeedback,
  CInputRadio,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CSelect,
} from "@coreui/react";
import {
  getAudienceRequest,
  readCampaignRequest,
  fetchCampaignTemplatesRequest,
  updatePartialCampaignRequest,
  getCampaignTemplatesRequest,
  getSendersRequest,
} from "../../../services/api";
import { useParams } from "react-router-dom";
import { showNotification } from "../../../utils/actionWrappers";
import { getTimeout } from "../../../utils/index";
import { useFormik } from "formik";
import * as yup from "yup";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { getTime, getDate } from "../../../utils";
import { useTranslation } from "react-i18next";
import CampaignSendBtn from "./CampaignSendBtn";
import { validationSchema } from "./CampaignEdit.model";
import { CampaignTypes } from "../../../models/Campaign.model";
import { useHistory } from "react-router-dom";
import "./CampaignEdit.scss";

const generatePayload = ({
  sender,
  campaignType,
  subject,
  audience,
  sendingDate,
  sendingTime,
  timeoutUnits,
  timeoutValue,
  isDraft,
}) => {
  const data = {
    ...(sender ? { sender } : { sender: null }),
    ...(subject ? { subject } : { subject: null }),
    ...(audience && { audience }),
    planned_at: null,
    is_draft: isDraft,
    timeout: null,
  };
  if (campaignType === CampaignTypes.Planned && sendingDate) {
    const planned_at = `${sendingDate}T${sendingTime}`;
    return { ...data, planned_at };
  }
  if (campaignType === CampaignTypes.Auto) {
    const unitsMapping = {
      minutes: (value) => Number(value) * 60,
      hours: (value) => Number(value) * 60 * 60,
      days: (value) => Number(value) * 60 * 60 * 24,
    };

    const timeout = unitsMapping[timeoutUnits](timeoutValue);
    return { ...data, timeout, is_active: !isDraft };
  }
  return data;
};

const CampaignExact = () => {
  const { t } = useTranslation();
  const [audiences, setAudiences] = useState([]);
  const [senders, setSenders] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const history = useHistory();
  const { id: campaignId } = useParams();
  const [templateBody, setTemplateBody] = useState("");

  const sendCampaignForm = useFormik({
    initialValues: {
      isDraft: true,
      sender: "",
      subject: "",
      audience: "",
      campaignType: null,
      sendingDate: "",
      sendingTime: "",
      timeoutValue: "0",
      timeoutUnits: "minutes",
      isAudienceEmpty: true,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema(templateBody),
    onSubmit: (values) => {
      const data = generatePayload(values);
      updatePartialCampaignRequest(campaignId, {
        ...data,
        ...(campaign.mailchimp_key && { mailchimp_key: campaign.mailchimp_key }),
        ...(campaign.mailchimp_server_prefix && { mailchimp_server_prefix: campaign.mailchimp_server_prefix }),
        ...(templateBody && { body: templateBody }),
      }).then(({ data }) => {
        setCampaign((state) => ({
          ...state,
          ...data,
        }));
        if (!values.isDraft) {
          history.push("/audiences");
        }
        showNotification(
          t("notification:title.success", "Success!"),
          !values.isDraft
            ? values.campaignType !== CampaignTypes.Flash
              ? t("notification:message.campaign.planned", "Campaign has been saved and scheduled")
              : t("notification:message.campaign.send", "Campaign has been saved and sent")
            : values.campaignType !== CampaignTypes.Flash
            ? t("notification:message.campaign.plannedDraft", "Campaign was saved as a draft and scheduled")
            : t("notification:message.campaign.sendDraft", "Campaign was saved as a draft"),
          "success"
        );
      });
    },
  });

  const getSenderName = (id) => {
    const { name } = senders.find((item) => item.id === Number(id)) || "";
    return name;
  };

  useEffect(() => {
    getAudienceRequest().then(({ data }) => setAudiences(data.sort((a, b) => b.id - a.id)));
    readCampaignRequest(campaignId).then(({ data }) => setCampaign(data));
    getSendersRequest().then(({ data }) => setSenders(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBtnClickHandler = (isCampaignSending) => {
    sendCampaignForm.values.isDraft = isCampaignSending;
    if (sendCampaignForm.values.audience) {
      const { total_subscribers } = audiences.find((item) => item.id === Number(sendCampaignForm.values.audience));
      sendCampaignForm.values.isAudienceEmpty = !total_subscribers;
    }
    sendCampaignForm.handleSubmit();
  };

  const onTemplateClickHandler = ({ target: { value } }) => {
    fetchCampaignTemplatesRequest(campaignId, value).then(({ data: { body } }) => setTemplateBody(body));
  };

  const fetchTemplatesForm = useFormik({
    initialValues: {
      mailchimp_key: "",
      mailchimp_server_prefix: "",
    },
    validateOnChange: false,
    validationSchema: yup.object({
      mailchimp_key: yup.string().required(t("form:error.required", "This field is required!")),
      mailchimp_server_prefix: yup.string().required(t("form:error.required", "This field is required!")),
    }),
    onSubmit: ({ mailchimp_key, mailchimp_server_prefix }) => {
      updatePartialCampaignRequest(campaignId, {
        mailchimp_key,
        mailchimp_server_prefix,
      }).then(() => {
        getCampaignTemplatesRequest(campaignId).then(() =>
          readCampaignRequest(campaignId).then(({ data }) => setCampaign(data))
        );
      });
    },
  });

  useEffect(() => {
    if (campaign) {
      const date = getDate(campaign.planned_at);
      const time = getTime(campaign.planned_at);
      const timeout = getTimeout(campaign.timeout);
      sendCampaignForm.resetForm({
        values: {
          ...sendCampaignForm.values,
          subject: sendCampaignForm.values.subject || campaign.subject || "",
          audience: sendCampaignForm.values.audience || campaign.audience || "",
          sender: sendCampaignForm.values.sender || campaign.sender || "",
          sendingDate: sendCampaignForm.values.sendingDate || date || "",
          sendingTime: sendCampaignForm.values.sendingTime || time || "",
          campaignType:
            sendCampaignForm.values.campaignType ||
            (campaign.timeout ? CampaignTypes.Auto : date ? CampaignTypes.Planned : CampaignTypes.Flash),
          timeoutValue: timeout.timeoutValue || sendCampaignForm.values.timeoutValue,
          timeoutUnits: timeout.timeoutUnits || sendCampaignForm.values.timeoutUnits,
        },
      });
      fetchTemplatesForm.resetForm({
        values: {
          ...fetchTemplatesForm.values,
          mailchimp_key: campaign.mailchimp_key || "",
          mailchimp_server_prefix: campaign.mailchimp_server_prefix || "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const handleBlur = () => {
    if (sendCampaignForm.values.timeoutValue < 0) {
      sendCampaignForm.resetForm({
        values: {
          ...sendCampaignForm.values,
          timeoutValue: "5",
          timeoutUnits: "minutes",
        },
      });
    }
  };

  return (
    <CCard className="campaignEdit">
      <CCardHeader>
        <h1>{t("page.campaign.edit", "Edit campaign")}</h1>
      </CCardHeader>
      <CCardBody>
        <CCardTitle>{campaign?.name}</CCardTitle>
        <CRow className="mt-4">
          <CCol xs="12" lg="6">
            <CFormGroup>
              <CInputGroup>
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.senderEmail", "Sender's email")}</CInputGroupText>
                </CInputGroupPrepend>
                <CSelect
                  className="form-control--no-back"
                  onChange={sendCampaignForm.handleChange}
                  invalid={!!sendCampaignForm.errors.sender}
                  name="sender"
                  id="sender"
                  value={sendCampaignForm.values.sender}
                >
                  {sendCampaignForm.values.sender === "" && (
                    <option>{t("form:label.senderSelect", "Choose an sender")}</option>
                  )}
                  {senders.map(({ email, id }) => (
                    <option key={id} value={id}>
                      {email}
                    </option>
                  ))}
                </CSelect>
                <CInvalidFeedback>{sendCampaignForm.errors.sender}</CInvalidFeedback>
              </CInputGroup>
            </CFormGroup>
            <CFormGroup>
              <CInputGroup>
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.senderName", "Sender's name")}</CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  className="form-control--disabled-white"
                  placeholder={t("form:placeholder.senderName", "Enter sender name")}
                  defaultValue={getSenderName(sendCampaignForm.values.sender) || ""}
                  disabled
                />
              </CInputGroup>
            </CFormGroup>
            <CFormGroup>
              <CInputGroup>
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.subject", "Subject")}</CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="text"
                  id="subject"
                  name="subject"
                  autoComplete="subject"
                  placeholder={t("form:placeholder.subject", "Enter subject")}
                  onChange={sendCampaignForm.handleChange}
                  value={sendCampaignForm.values.subject}
                  invalid={!!sendCampaignForm.errors.subject}
                  required
                />
                <CInvalidFeedback>{sendCampaignForm.errors.subject}</CInvalidFeedback>
              </CInputGroup>
            </CFormGroup>
            <CFormGroup>
              <CInputGroup>
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.audience", "Audience")}</CInputGroupText>
                </CInputGroupPrepend>
                <CSelect
                  className="form-control--no-back"
                  onChange={sendCampaignForm.handleChange}
                  name="audience"
                  value={sendCampaignForm.values.audience}
                  invalid={!!sendCampaignForm.errors.audience || !!sendCampaignForm.errors.isAudienceEmpty}
                >
                  {sendCampaignForm.values.audience === "" && (
                    <option>{t("form:label.audiencesSelect", "Select an audience")}</option>
                  )}
                  {audiences.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </CSelect>
                <CInvalidFeedback>
                  {sendCampaignForm.errors.audience && sendCampaignForm.errors.subject}
                </CInvalidFeedback>
                <CInvalidFeedback>{sendCampaignForm.errors.isAudienceEmpty}</CInvalidFeedback>
              </CInputGroup>
            </CFormGroup>
          </CCol>
        </CRow>
        <CForm id="mailchimp-form" className="form-horizontal" onSubmit={fetchTemplatesForm.handleSubmit}>
          <CLabel>{t("form:label.template", "Choose a template")}</CLabel>
          <CRow>
            <CCol className="mb-3" md="12" lg="4">
              <CInput
                placeholder={t("form:placeholder.mailchimpToken", "Mailchimp token")}
                type="text"
                id="mailchimp_key"
                name="mailchimp_key"
                required
                onChange={fetchTemplatesForm.handleChange}
                value={fetchTemplatesForm.values.mailchimp_key}
                invalid={!!fetchTemplatesForm.errors.mailchimp_key}
              />
              <CInvalidFeedback>{fetchTemplatesForm.errors.mailchimp_key}</CInvalidFeedback>
            </CCol>
            <CCol className="mb-3" md="12" lg="2">
              <CInput
                placeholder={t("form:placeholder.mailchimpServerPrefix", "Mailchimp Server Prefix")}
                type="text"
                required
                id="mailchimp_server_prefix"
                name="mailchimp_server_prefix"
                onChange={fetchTemplatesForm.handleChange}
                value={fetchTemplatesForm.values.mailchimp_server_prefix}
                invalid={!!fetchTemplatesForm.errors.mailchimp_server_prefix}
              />
              <CInvalidFeedback>{fetchTemplatesForm.errors.mailchimp_server_prefix}</CInvalidFeedback>
            </CCol>
            <CCol className="mb-3" md="12" lg="4">
              <CButton type="submit" form="mailchimp-form" color="primary" onClick={fetchTemplatesForm.handleSubmit}>
                {t("form:button.fetchingTemplates", "Fetching templates")}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
        {campaign && (
          <CRow className="mb-3">
            <CCol xs="12" lg="6">
              <CFormGroup>
                <CInputGroup>
                  <CInputGroupPrepend>
                    <CInputGroupText>{t("form:label.template", "Template")}</CInputGroupText>
                  </CInputGroupPrepend>
                  <CSelect
                    className="form-control--no-back"
                    onChange={onTemplateClickHandler}
                    invalid={!!sendCampaignForm.errors.templateBody}
                    name="template"
                    value={sendCampaignForm.values.template}
                  >
                    {!templateBody && <option>{t("form:label.templateSelect", "Select a template")}</option>}
                    {campaign.templates.map(({ name, id, mailchimp_id }) => (
                      <option key={id} value={mailchimp_id}>
                        {name}
                      </option>
                    ))}
                  </CSelect>
                  <CInvalidFeedback>{sendCampaignForm.errors.subject}</CInvalidFeedback>
                </CInputGroup>
              </CFormGroup>
            </CCol>
          </CRow>
        )}
        <CRow>
          <CCol xs="12" lg="8">
            <CFormGroup>
              <div className="form-check mb-3">
                <label className="form-check-label">
                  <CInputRadio
                    className="form-check-input"
                    onChange={sendCampaignForm.handleChange}
                    value={CampaignTypes.Flash}
                    type="radio"
                    checked={sendCampaignForm.values.campaignType === CampaignTypes.Flash}
                    name="campaignType"
                  />
                  {t("form:label.SendImmediately", "Send immediately")}
                </label>
              </div>
              <CRow className="">
                <CCol lg="4" className="mb-2">
                  <div className="form-check">
                    <label className="form-check-label">
                      <CInputRadio
                        onChange={sendCampaignForm.handleChange}
                        className="form-check-input"
                        value={CampaignTypes.Planned}
                        type="radio"
                        name="campaignType"
                        checked={sendCampaignForm.values.campaignType === CampaignTypes.Planned}
                      />
                      {t("form:label.startSendingAt", "Start sending at")}
                    </label>
                  </div>
                </CCol>
                <CCol md="6" lg="3" className="mb-2">
                  <CInput
                    onChange={sendCampaignForm.handleChange}
                    type="date"
                    id="sendingDate"
                    name="sendingDate"
                    value={sendCampaignForm.values.sendingDate}
                    placeholder="date"
                    disabled={sendCampaignForm.values.campaignType !== CampaignTypes.Planned}
                    invalid={!!sendCampaignForm.errors.sendingDate}
                  />
                  <CInvalidFeedback className="d-block">{sendCampaignForm.errors.sendingDate}</CInvalidFeedback>
                </CCol>
                <CCol md="6" lg="3" className="mb-2">
                  <CInput
                    onChange={sendCampaignForm.handleChange}
                    type="time"
                    id="sendingTime"
                    name="sendingTime"
                    value={sendCampaignForm.values.sendingTime}
                    placeholder="time"
                    invalid={!!sendCampaignForm.errors.sendingTime}
                    disabled={sendCampaignForm.values.campaignType !== CampaignTypes.Planned}
                  />
                  <CInvalidFeedback className="d-block">{sendCampaignForm.errors.sendingTime}</CInvalidFeedback>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="4" className="mb-2">
                  <div className="form-check">
                    <label className="form-check-label">
                      <CInputRadio
                        onChange={sendCampaignForm.handleChange}
                        className="form-check-input"
                        value={CampaignTypes.Auto}
                        type="radio"
                        name="campaignType"
                        checked={sendCampaignForm.values.campaignType === CampaignTypes.Auto}
                      />
                      {t("form:label.automail", "Automatic mailing (starts when subscriber is added)")}
                    </label>
                  </div>
                </CCol>
                <CCol md="6" lg="3" className="mb-2">
                  <CInput
                    name="timeoutValue"
                    id="timeoutValue"
                    onChange={sendCampaignForm.handleChange}
                    value={sendCampaignForm.values.timeoutValue}
                    onBlur={handleBlur}
                    disabled={sendCampaignForm.values.campaignType !== CampaignTypes.Auto}
                    type="number"
                  />
                  <CInvalidFeedback className="d-block">{sendCampaignForm.errors.timeoutValue}</CInvalidFeedback>
                </CCol>
                <CCol md="6" lg="3" className="mb-2">
                  <CSelect
                    name="timeoutUnits"
                    id="timeoutUnits"
                    onChange={sendCampaignForm.handleChange}
                    value={sendCampaignForm.values.timeoutUnits}
                    disabled={sendCampaignForm.values.campaignType !== CampaignTypes.Auto}
                  >
                    <option value="minutes">{t("form:placeholder.minutes", "Minutes")}</option>
                    <option value="hours">{t("form:placeholder.hours", "Hours")}</option>
                    <option value="days">{t("form:placeholder.days", "Days")}</option>
                  </CSelect>
                  <CInvalidFeedback className="d-block">{sendCampaignForm.errors.timeoutUnits}</CInvalidFeedback>
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs="12" lg="6">
            <CFormGroup>
              <CButton onClick={() => onBtnClickHandler(true)} className="mb-3 mr-3" color="primary">
                {t("form:button.saveAsADraft", "Save as a draft")}
              </CButton>
              <CampaignSendBtn onClick={() => onBtnClickHandler(false)} type={sendCampaignForm.values.campaignType} />
            </CFormGroup>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(CampaignExact);
