import React from "react";
import { CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { campaignTypeMapping } from "../../../models/Campaign.model";

const CampaignSendBtn = ({ type, onClick }) => {
  const { t } = useTranslation();

  return (
    <CButton onClick={() => onClick(true)} className="mb-3" color={campaignTypeMapping[type]?.button.color || "primary"}>
      {campaignTypeMapping[type]?.button.sign() || t("form:button.send", "Send")}
    </CButton>
  );
};

export default withErrorBoundary(CampaignSendBtn);
