import {MODAL_WINDOW} from "../actions/types";

const initialState = {
  title: "",
  message: "",
  isShowing: false,
  type: "alert",
  color: "info",
  callbackOK: null,
  callbackCancel: null
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_WINDOW.SHOW:
      return {
        ...state,
        ...action.payload,
        isShowing: true,
      };
    case MODAL_WINDOW.HIDE:
      return {
        ...state,
        callbackOK: null,
        callbackCancel: null,
        isShowing: false
      };
    default:
      return state;
  }
};

export default modalReducer;