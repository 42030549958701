import React from "react";
import "./ARFlag.scss";

const ARFlag = ({country, className, flagClassName}) => (
  <div className={`flags-container ${className}`}>
    <div className={`ARFlag flag ${country} ${flagClassName}`}/>
  </div>
);

ARFlag.defaultProps = {
  className: "",
  flagClassName: ""
};

export default ARFlag;