import React, { useCallback, useEffect, useState } from "react";
import { CCard, CCardHeader, CRow, CCardBody, CDataTable, CButton, CImg, CJumbotron } from "@coreui/react";
import "./Members.scss";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { getMembersRequest } from "../../services/api";
import { getTableColumnHeaders, getTableFilterText, getItemsPerPageSelectValues } from "./Members.model";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTableNoItemsView } from "../../utils";

const Members = () => {
  const [members, setMembers] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [tableFilterValue, setTableFilterValue] = useState("");
  const [filterTimeout, setFilterTimeout] = useState(null);
  const [sorterValue, setSorterValue] = useState({ column: "id", desc: true });
  const [isMembersListEmptyOnServer, setIsMembersListEmptyOnServer] = useState(true);

  const query = new URLSearchParams({ search: tableFilterValue }).toString();

  const getSortedMembers = useCallback(
    (items) => {
      const flip = sorterValue.asc ? 1 : -1;
      const sorted = items.slice().sort((item, item2) => {
        const value = item[sorterValue.column];
        const value2 = item2[sorterValue.column];
        const a = typeof value === "number" ? value : String(value).toLowerCase();
        const b = typeof value2 === "number" ? value2 : String(value2).toLowerCase();
        return a > b ? 1 * flip : b > a ? -1 * flip : 0;
      });
      return sorted;
    },
    [sorterValue.asc, sorterValue.column]
  );

  useEffect(() => {
    getMembersRequest(query).then(({ data }) => {
      setMembers(getSortedMembers(data));
    });
  }, [query, getSortedMembers]);

  useEffect(() => {
    setMembers((state) => getSortedMembers(state));
  }, [sorterValue, getSortedMembers]);


  useEffect(() => {
    getMembersRequest().then(({ data }) => {
      setIsMembersListEmptyOnServer(data.length === 0);
      setMembers(getSortedMembers(data));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTable = (value) => {
    clearTimeout(filterTimeout);

    const timeout = setTimeout(() => {
      setTableFilterValue(value);
    }, 400);

    setFilterTimeout(timeout);
  };

  return (
    <CCard className="MembersCard">
      <CCardHeader>
        <h1>{t("page.members", "Members")}</h1>
      </CCardHeader>
      <CCardBody>
        <CRow className="MembersCard__over-table-row over-table-row">
          <CButton tag={Link} to="/members/add" color="primary" className="over-table-row__add-button">
            {t("button.add", "Add")}
          </CButton>
        </CRow>
        {isMembersListEmptyOnServer ? (
          <CJumbotron>
            <h3 className="text-center">{t("emptyList.members", "There will be a list of your members here")}</h3>
          </CJumbotron>
        ) : (
          <CDataTable
            items={members}
            fields={getTableColumnHeaders()}
            hover
            tableFilter={getTableFilterText()}
            sorter={{ external: true }}
            onSorterValueChange={setSorterValue}
            responsive
            itemsPerPageSelect={getItemsPerPageSelectValues()}
            itemsPerPage={10}
            onTableFilterChange={filterTable}
            tableFilterValue={tableFilterValue}
            sorterValue={sorterValue}
            noItemsView={getTableNoItemsView()}
            pagination
            striped
            onRowClick={(item) => history.push(`/members/${item.id}`)}
            border
            addTableClasses="MembersCard__members-table members-table"
            clickableRows
            scopedSlots={{
              first_name: (item) => (
                <td className="members-table__cell">
                  <div className="user-info-content">
                    <div className="user-info-content__c-avatar c-avatar">
                      <CImg
                        src={item.avatar || "/avatars/1.png"}
                        className="c-avatar__image c-avatar-img"
                        alt="member"
                      />
                    </div>
                    <div className="user-info-content__text">{item.first_name || item.email || ""}</div>
                  </div>
                </td>
              ),
              last_name: (item) => <td className="members-table__cell">{item.last_name || ""}</td>,
              email: (item) => (
                <td className="members-table__cell">
                  <div className="members-table__link">
                    {item.email && (
                      <a href={`mailto: ${item.email}`} onClick={(event) => event.stopPropagation()}>
                        <div className="link-content">{item.email}</div>
                      </a>
                    )}
                  </div>
                </td>
              ),
              member_directory: (item) => (
                <td className="members-table__cell">
                  {item.member_directory || t("table:internalDirectory", "Internal directory")}
                </td>
              ),
            }}
          />
        )}
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(Members);
