import React from "react";
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CRow, CCol } from "@coreui/react";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";

const AudiencesRemoveModal = ({ toggleModal, campaigns }) => {
  const { t } = useTranslation();

  return (
    <CModal show={!!campaigns} centered color="info" onClose={toggleModal}>
      <CModalHeader closeButton></CModalHeader>
      <CModalBody>
        <p>
          {t("modal:title.audienceDeleteAlert", "To remove the audience in use, first remove the affected campaign")}
        </p>
        <CRow>
          <CCol className="col-4">
            <h5>{t("modal:message.campaignName", "Campaign Name")}</h5>
          </CCol>
          <CCol className="col-8">
            <ul>{campaigns && campaigns.map((item) => <li key={item.id}>{item.name}</li>)}</ul>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={toggleModal} color="info">
        {t("button.ok", "Ok")}
        </CButton>{" "}
      </CModalFooter>
    </CModal>
  );
};

export default withErrorBoundary(AudiencesRemoveModal);
