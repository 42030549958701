import {GLOBAL} from "./types";
import {getTenantInfoRequest} from "../../services/api";

export function showFetchingAction() {
  return {
    type: GLOBAL.FETCHING
  };
}

export function hideFetchingAction() {
  return {
    type: GLOBAL.FETCHED
  };
}

export function setTenantAction() {
  return async dispatch => {
    const response = await getTenantInfoRequest();
    dispatch({
      type: GLOBAL.SET_TENANT,
      payload: response
    });
  };
}

export function setHasAdminAction(hasAdmin) {
  return {
    type: GLOBAL.SET_HAS_ADMIN,
    payload: {hasAdmin}
  };
}