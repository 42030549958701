import React, {useState, useEffect} from "react";
import {CAlert} from "@coreui/react";
import {useSelector} from "react-redux";
import {useActions} from "../../store/actions";
import {hideNotificationAction} from "../../store/actions/notification";
import "./Notification.scss";

const Notification = (props) => {
  const {title, message, type, isShowing} = useSelector(state => state.notification);
  const hideNotification = useActions(hideNotificationAction);
  const [visible, setVisible] = useState(isShowing ? props.timeout || 20 : 0);

  const onShowChange = (value) => {
    setVisible(+value);
    if (value === 0) hideNotification();
  };

  useEffect(() => {
    if (isShowing) {
      setVisible(props.timeout || 10);
    }
  }, [title, message, type, isShowing, props.timeout]);

  return (
    <div className="Notification-container">
      <CAlert
        className="Notification"
        color={type}
        show={visible}
        closeButton
        onShowChange={onShowChange}
      >
        <h4 className="alert-heading">{title}</h4>
        <p>{message}</p>
      </CAlert>
    </div>
  );
};

export default Notification;