import React, { useState, useEffect } from "react";
import { CCol, CInput, CButton, CRow, CInvalidFeedback, CForm } from "@coreui/react";
import { getAudienceRequest, updatePartialAudienceRequest } from "../../services/api/index.js";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../utils/actionWrappers";

const AudienceChangeNameFrom = ({ audience: { id, addresses, name }, setAudience }) => {
  const [audienceNameInput, setAudienceNameInput] = useState({ value: "", isValid: null });
  const { t } = useTranslation();

  const onAudienceNameChange = (e) => {
    setAudienceNameInput({
      value: e.target.value,
      isValid: e.target.validity.valid,
    });
  };

  useEffect(() => {
    setAudienceNameInput({ value: name, isValid: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAudienceNameSubmit = async (e) => {
    e.preventDefault();
    const { data: audiences } = await getAudienceRequest();
    const audience = audiences.find((item) => item.name === audienceNameInput.value);
    if (audience) {
      showNotification(
        t("notification:title.error", "Something went wrong!"),
        t("notification:message.error.audienceIsNotUniq", " Audience with this name already exists."),
        "danger"
      );
    } else {
      const { data } = await updatePartialAudienceRequest(id, {
        name: audienceNameInput.value,
        addresses,
      });
      setAudience(data);
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.audience", " Audience has been updated"),
        "success"
      );
    }
  };

  const onAudienceNameFocus = (e) => {
    e.target.select();
  };

  return (
    <CForm className="mb-3" onSubmit={onAudienceNameSubmit} id="audience-name-form">
      <CRow>
        <CCol xs="6" lg="6" xl="3">
          <CInput
            invalid={audienceNameInput.isValid === false}
            onChange={onAudienceNameChange}
            onFocus={onAudienceNameFocus}
            value={audienceNameInput.value}
            type="text"
            required
            className="audienceExact__input"
          />
          <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
        </CCol>
        <CCol xs="12" lg="3">
          <CButton
            disabled={!audienceNameInput.isValid || name === audienceNameInput.value}
            form="audience-name-form"
            color="primary"
            type="submit"
          >
            {t("form:button.changeName", "Change Name")}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default withErrorBoundary(AudienceChangeNameFrom);
