import React, { useState, useRef, useEffect } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CInput, CInputFile, CRow } from "@coreui/react";
import { getAudienceRequest, createAudienceRequest } from "../../services/api";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import AudiencesTable from "./AudiencesTable";
import { useHistory } from "react-router-dom";
import { showNotification } from "../../utils/actionWrappers";

const Audience = () => {
  const [audiences, setAudiences] = useState([]);
  const fileInput = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    getAudienceRequest().then(({ data }) => {
      if (isMounted) {
        setAudiences(data.sort((a, b) => b.id - a.id));
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const audienceInitialState = {
    value: "",
    isValid: null,
  };

  const fileInitialState = {
    value: null,
    isValid: null,
  };

  const [inputValues, setInputValues] = useState({
    name: audienceInitialState,
    file: fileInitialState,
  });

  const onInputNameChange = (e) => {
    setInputValues((state) => ({
      ...state,
      name: {
        value: e.target.value,
        isValid: e.target.validity.valid,
      },
    }));
  };

  const onInputFileChange = (e) => {
    setInputValues((state) => ({
      ...state,
      file: {
        value: e.target.files[0],
        isValid: !!e.target.files[0],
      },
    }));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    fileInput.current.value = null;
    const values = Object.entries(inputValues).reduce((acc, [name, data]) => {
      if (data.value) return { ...acc, [name]: data.value };
      return acc;
    }, {});
    const audience = audiences.find((item) => item.name === values.name);
    if (audience) {
      showNotification(
        t("notification:title.error", "Something went wrong!"),
        t("notification:message.error.audienceIsNotUniq", " Audience with this name already exists."),
        "danger"
      );
    } else {
      createAudienceRequest(values).then((response) => {
        history.push(`/audience/${response.data.id}`);
      });
    }
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <h1>{t("page.audiences", "Audiences")}</h1>
        </CCardHeader>
        <CCardBody>
          <CForm onSubmit={onFormSubmit}>
            <CRow className="mb-3">
              <CCol xs="6">
                <CInput
                  value={inputValues.name.value}
                  onChange={onInputNameChange}
                  type="text"
                  id="name"
                  name="name"
                  autoComplete="name"
                  invalid={inputValues.name.isValid === false}
                  required
                />
              </CCol>
              <CCol xs="6">
                <CButton disabled={!inputValues.name.isValid} type="submit" color="primary" className="px-4">
                  {t("form:button.add", "Add")}
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="6">
                <CInputFile
                  className="mb-1"
                  id="file"
                  innerRef={fileInput}
                  name="file"
                  onChange={onInputFileChange}
                  type="file"
                  accept=".txt, .csv, .xlsx"
                />
                <p> {t("form:description.fileExtensions", "Supported file extensions: .xlsx, .csv")}</p>
              </CCol>
            </CRow>
          </CForm>
          <AudiencesTable audiences={audiences} setAudiences={setAudiences} />
        </CCardBody>
      </CCard>
    </>
  );
};

export default withErrorBoundary(Audience);
