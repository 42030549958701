import i18next from "../../services/language/i18next";

const getTableColumnHeaders = () => [
  { key: "name", label: i18next.t("table:header.campaignName", "Campaign Name"), _style: { width: "40%" } },
  { key: "status", label: i18next.t("table:header.status", "Status"), _style: { width: "10%" } },
  { key: "sent_at", label: i18next.t("table:header.sendAt", "Sent at"), _style: { width: "20%" } },
  { key: "planned_at", label: i18next.t("table:header.plannedAt", "Planned at"), _style: { width: "20%" } },
  { key: "statistics", label: "", _style: { width: "10%" } },
  { key: "remove", label: "", _style: { width: "10%" } },
];

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [10, 50, 100],
});

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label"),
  placeholder: i18next.t("table:filter.placeholder.campaigns", "Search for campaigns"),
  external: true,
});

export { getTableColumnHeaders, getItemsPerPageSelectValues, getTableFilterText };
