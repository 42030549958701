import React from "react";

import {CCard, CCardBody, CCardGroup, CCol, CContainer,CHeaderNav, CRow} from "@coreui/react";
import routeNetwork from "../../../routes";
import Content from "../Content";
import {useSelector} from "react-redux";
import HeaderLanguageDropdown from "../AuthorizedPageWrapper/Header/HeaderLanguageDropdown";

const UnAuthorizedPageWrapper = (props) => {
  const state = useSelector(state => state);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div className="d-flex align-items-start">
                    <CHeaderNav className="ml-auto">
                      <HeaderLanguageDropdown />
                    </CHeaderNav>
                  </div>
                  <Content routes={routeNetwork.getRoutes(state)} />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );

};

export default UnAuthorizedPageWrapper;