import { Trans } from "react-i18next";
import i18next from "../../../services/language/i18next";
import * as yup from "yup";
import { getDate } from "../../../utils/index";
import { CampaignTypes } from "../../../models/Campaign.model";

const validationSchema = (templateBody) => {
  const currentDateAndTime = Date.now();
  const currentDateAtMidnight = Date.parse(`${getDate(currentDateAndTime)} 00:00:00`);

  return yup.object({
    sender: yup
      .string()
      .when("isDraft", {
        is: false,
        then: yup.string().required(i18next.t("form:error.required", "This field is required!")),
      })
      .when("campaignType", {
        is: CampaignTypes.Auto,
        then: yup.string().required(i18next.t("form:error.required", "This field is required!")),
      }),
    subject: yup
      .string()
      .when("isDraft", {
        is: false,
        then: yup.string().required(i18next.t("form:error.required", "This field is required!")),
      })
      .when("campaignType", {
        is: CampaignTypes.Auto,
        then: yup.string().required(i18next.t("form:error.required", "This field is required!")),
      }),
    audience: yup
      .mixed()
      .when("isDraft", {
        is: false,
        then: yup.mixed().required(),
      })
      .when("campaignType", {
        is: CampaignTypes.Auto,
        then: yup.mixed().required(),
      }),
    isAudienceEmpty: yup.mixed().when("isDraft", {
      is: false,
      then: yup
        .mixed()
        .required()
        .when("campaignType", {
          is: (value) => value === CampaignTypes.Flash || value === CampaignTypes.Planned,
          then: yup.mixed().when("audience", {
            is: (val) => !!val,
            then: yup.boolean().oneOf([false], i18next.t("form:error.campaign.emptyAudience", "Audience is empty!")),
          }),
        }),
    }),
    templateBody: yup
      .string()
      .when("isDraft", {
        is: false,
        then: yup.string().test("template", () => !!templateBody),
      })
      .when("campaignType", {
        is: CampaignTypes.Auto,
        then: yup.string().test("template", () => !!templateBody),
      }),
    sendingDate: yup
      .string()
      .when("isDraft", {
        is: true,
        then: yup
          .string()
          .test(
            "sendingDate:",
            i18next.t(
              "form:error.campaign.dispatchDate",
              "Scheduled dispatch date must be greater than the current one"
            ),
            (value, context) => {
              if (!value) return true;
              if (context.parent.campaignType !== CampaignTypes.Planned) return true;
              const plannedDateAtMidnight = Date.parse(`${value} 00:00:00`);
              return plannedDateAtMidnight >= currentDateAtMidnight;
            }
          ),
      })
      .when("isDraft", {
        is: false,
        then: yup
          .string()
          .test("sendingDate:", i18next.t("form:error.campaign.sendingDate", "Choose date"), (value, context) =>
            context.parent.campaignType === CampaignTypes.Planned ? !!value : true
          )
          .test(
            "sendingDate:",
            i18next.t(
              "form:error.campaign.dispatchDate",
              "Scheduled dispatch date must be greater than the current one"
            ),
            (value, context) => {
              if (context.parent.campaignType !== CampaignTypes.Planned) return true;
              const plannedDateAtMidnight = Date.parse(`${value} 00:00:00`);
              return plannedDateAtMidnight >= currentDateAtMidnight;
            }
          ),
      }),
    sendingTime: yup
      .string()
      .when("isDraft", {
        is: true,
        then: yup.string().when("sendingDate", {
          is: (val) => !!val,
          then: yup
            .string()
            .test("sendingTime:", i18next.t("form:error.campaign.sendingTime", "Choose time"), (value, context) =>
              context.parent.campaignType === CampaignTypes.Planned ? !!value : true
            )
            .test(
              "sendingTime:",
              i18next.t(
                "form:error.campaign.dispatchTime",
                "Scheduled dispatch time must be greater than the current one"
              ),
              (value, context) => {
                if (context.parent.campaignType !== CampaignTypes.Planned) return true;
                const plannedDateAndTime = Date.parse(`${context.parent.sendingDate} ${value}`);
                const plannedDateAtMidnight = Date.parse(`${context.parent.sendingDate} 00:00:00`);
                if (plannedDateAndTime > currentDateAndTime) return true;
                return plannedDateAtMidnight < currentDateAtMidnight;
              }
            ),
        }),
      })
      .when("isDraft", {
        is: false,
        then: yup
          .string()
          .test("sendingTime:", i18next.t("form:error.campaign.sendingTime", "Choose time"), (value, context) =>
            context.parent.campaignType === CampaignTypes.Planned ? !!value : true
          )
          .test(
            "sendingTime:",
            i18next.t(
              "form:error.campaign.dispatchTime",
              "Scheduled dispatch time must be greater than the current one"
            ),
            (value, context) => {
              if (context.parent.campaignType !== CampaignTypes.Planned) return true;
              const plannedDateAndTime = Date.parse(`${context.parent.sendingDate} ${value}`);
              const plannedDateAtMidnight = Date.parse(`${context.parent.sendingDate} 00:00:00`);
              if (plannedDateAndTime > currentDateAndTime) return true;
              return plannedDateAtMidnight < currentDateAtMidnight;
            }
          ),
      }),
    timeoutValue: yup
      .string()
      .test("timeoutValue:", (value, context) => (context.parent.campaignType === CampaignTypes.Auto ? !!value : true)),
    timeoutUnits: yup
      .string()
      .test("timeoutUnits:", (value, context) => (context.parent.campaignType === CampaignTypes.Auto ? !!value : true)),
  });
};

export { validationSchema };
