import React, { useState, useRef } from "react";
import { CCol, CInput, CButton, CRow, CForm, CInvalidFeedback, CInputFile } from "@coreui/react";
import { updatePartialAudienceRequest } from "../../services/api/index.js";
import { emailValidation } from "../../utils/index";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { Trans, useTranslation } from "react-i18next";
import { showNotification } from "../../utils/actionWrappers";

const AudienceAddSubscriptionsForm = ({ audience: { id, addresses }, setAudience }) => {
  const fileInput = useRef(null);
  const { t } = useTranslation();

  const [inputFile, setInputFile] = useState({ value: "", isValid: null });
  const [inputEmail, setInputEmail] = useState({ value: "", isValid: null });

  const onInputFileChange = (e) => {
    setInputFile({
      value: e.target.files[0],
      isValid: !!e.target.files[0],
    });
  };

  const onInputEmailChange = (e) => {
    setInputEmail({
      value: e.target.value,
      isValid: emailValidation.test(e.target.value),
    });
  };

  const onInputFileSubmit = (e) => {
    e.preventDefault();
    updatePartialAudienceRequest(id, { file: inputFile.value }).then(({ data }) => setAudience(data));
    fileInput.current.value = null;
    setInputFile({ value: "", isValid: null });
  };

  const onInputEmailSubmit = (e) => {
    e.preventDefault();
    const subscriber = addresses.find((item) => item.email === inputEmail.value);
    if (subscriber) {
      showNotification(
        t("notification:title.error", "Error!"),
        t("notification:message.error.subscriberIsNotUniq", "Subscriber already exists!"),
        "danger"
      );
    } else {
      const updatedAddresses = [...addresses, { email: inputEmail.value }];
      updatePartialAudienceRequest(id, { addresses: updatedAddresses }).then(({ data }) => setAudience(data));
      setInputEmail({ value: "", isValid: null });
    }
  };

  return (
    <>
      <CRow>
        <CCol>
          <h3 className="display-7 mb-3">{t("text.audienceDetails.addNewEmails", "Add new emails:")}</h3>
        </CCol>
      </CRow>
      <CForm className="mb-0" id="audience-add-file" onSubmit={onInputFileSubmit}>
        <CRow className="mb-1">
          <CCol xs="6" md="6" lg="6" xl="3">
            <CInputFile
              required
              onChange={onInputFileChange}
              id="file"
              innerRef={fileInput}
              name="file"
              type="file"
              accept=".txt, .csv, .xlsx"
            />
          </CCol>
          <CCol xs="6" lg="2">
            <CButton
              disabled={!inputFile.isValid}
              type="submit"
              color="primary"
              className="px-4"
              form="audience-add-file"
            >
              {t("form:button.upload", "Upload")}
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <p>{t("form:description.fileExtensions", "Supported file extensions: .xlsx, .csv")}</p>
          </CCol>
        </CRow>
      </CForm>
      <CForm className="mb-5" id="audience-add-subscriptions" onSubmit={onInputEmailSubmit}>
        <CRow>
          <CCol xs="6" md="6" lg="6" xl="3">
            <CInput
              valid={inputEmail.isValid}
              invalid={inputEmail.isValid === false}
              value={inputEmail.value}
              onChange={onInputEmailChange}
              id="email"
              name="email"
              type="email"
              className="audienceExact__input"
            />
            <CInvalidFeedback>
              <Trans i18nKey="form:error.email.value">
                You must enter your email in the format <b>user@domain.com</b>!
              </Trans>
            </CInvalidFeedback>
          </CCol>
          <CCol xs="6" lg="2">
            <CButton disabled={!inputEmail.isValid} type="submit" color="primary" className="px-4">
              {t("form:button.add", "Add")}
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default withErrorBoundary(AudienceAddSubscriptionsForm);
