import React, { useState, useMemo } from "react";
import { CDataTable, CButton } from "@coreui/react";
import { showModalWindow } from "../../../utils/actionWrappers";
import { useTranslation } from "react-i18next";
import { getTableColumnHeaders, getTableFilterText } from "./SendersTable.model";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import SenderEditModalWindow from "./SenderEditModalWindow";
import { getFilteredItems, getTableNoItemsView } from "../../../utils/index";
import { deleteSendersRequest, updateSendersRequest } from "../../../services/api";

const SendersTable = ({ senders, setSenders }) => {
  const { t } = useTranslation();
  const [tableFilterValue, setTableFilterValue] = useState("");
  const [showSenderEditModal, setShowSenderEditModal] = useState(false);
  const [sender, setSender] = useState({});

  const renameSender = async ({ id, name, email }) => {
    const { data } = await updateSendersRequest(id, { name, email });
    const updatedSenders = senders.map((item) => (item.id !== id ? item : data));
    setSenders(updatedSenders);
  };

  const filteredSenders = useMemo(
    () => getFilteredItems(senders, tableFilterValue, ["email", "name"]),
    [senders, tableFilterValue]
  );

  const deleteSender = (id) => {
    const updatedSenders = senders.filter((item) => item.id !== id);
    deleteSendersRequest(id).then(() => setSenders(updatedSenders));
  };

  const onButtonEditClick = (sender) => {
    setSender(sender);
    setShowSenderEditModal(true);
  };

  const onButtonRemoveClick = ({ name, id }) => {
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t("modal:message.delete.sender_withItemName", `Are you sure you want to delete sender ${name}?`, {
        itemName: name,
      }),
      "danger",
      () => deleteSender(id)
    );
  };

  return (
    <>
      <CDataTable
        pagination
        sorter
        onTableFilterChange={setTableFilterValue}
        tableFilter={getTableFilterText()}
        fields={getTableColumnHeaders()}
        tableFilterValue={tableFilterValue}
        noItemsView={getTableNoItemsView()}
        scopedSlots={{
          name: ({ name }) => <td>{name}</td>,
          edit: (item) => (
            <td>
              <CButton onClick={() => onButtonEditClick(item)} color="success" size="sm">
                {t("button.edit", "Edit")}
              </CButton>
            </td>
          ),
          remove: (item) => (
            <td>
              <CButton onClick={() => onButtonRemoveClick(item)} color="danger" size="sm">
                {t("button.remove", "Remove")}
              </CButton>
            </td>
          ),
        }}
        items={filteredSenders}
      />
      {showSenderEditModal && (
        <SenderEditModalWindow
          renameSender={renameSender}
          sender={sender}
          onHide={() => setShowSenderEditModal(false)}
        />
      )}
    </>
  );
};

export default withErrorBoundary(SendersTable);
