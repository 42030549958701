import axios from "axios";
import config from "../../configs";
import { alertErrors, logoutIfTokenIsInvalid, showFetching, showNotification } from "../../utils/actionWrappers";
import Cookies from "js-cookie";
import i18next from "../language/i18next";

const instance = axios.create({
  baseURL: `${config.ORIGIN}${config.API_BASE_URL}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (i18next?.language) {
      config.headers["Accept-Language"] = `${i18next.language}, *;q=0.9`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

function requestPromiseWrapper(promise) {
  return showFetching(alertErrors(logoutIfTokenIsInvalid(promise)));
}

export function getAudienceRequest() {
  return requestPromiseWrapper(instance.get("/audience/"));
}

export function deleteAudienceRequest(id) {
  return requestPromiseWrapper(instance.delete(`/audience/${id}/`));
}

export function readAudienceRequest(id) {
  return requestPromiseWrapper(instance.get(`/audience/${id}/`));
}

export function updatePartialAudienceRequest(id, data) {
  const getData = ({ file }) => {
    if (!file) return data;
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  };

  return requestPromiseWrapper(instance.patch(`/audience/${id}/`, getData(data)));
}

export function createAudienceRequest(data) {
  const getData = (data) => {
    if (!data.file) {
      return data;
    }
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("name", data.name);
    return formData;
  };
  return requestPromiseWrapper(instance.post("/audience/", getData(data)));
}

export function getCampaignRequest() {
  return requestPromiseWrapper(instance.get("/campaign/"));
}

export function getSendersRequest() {
  return requestPromiseWrapper(instance.get("/senders/"));
}

export function createSendersRequest(data) {
  return requestPromiseWrapper(instance.post("/senders/", data));
}

export function deleteSendersRequest(id) {
  return requestPromiseWrapper(instance.delete(`/senders/${id}/`));
}

export function updateSendersRequest(id, data) {
  return requestPromiseWrapper(instance.patch(`/senders/${id}/`, data));
}

export function getMembersRequest(query) {
  return requestPromiseWrapper(instance.get(query ? `/members?${query}` : "/members/"));
}

export function createMembersRequest(data) {
  return requestPromiseWrapper(instance.post("/members/", data));
}

export function updateMembersRequest(id, data) {
  return requestPromiseWrapper(instance.patch(`/members/${id}/`, data));
}

export function readMembersRequest(id) {
  return requestPromiseWrapper(instance.get(`/members/${id}/`));
}

export function deleteMembersRequest(id) {
  return requestPromiseWrapper(instance.delete(`/members/${id}/`));
}

export function getMemberDirectoryRequest() {
  return requestPromiseWrapper(instance.get("/member-directory/"));
}

export function readMemberDirectoryRequest(id) {
  return requestPromiseWrapper(instance.get(`/member-directory/${id}`));
}

export function createMemberDirectoryRequest(data) {
  return requestPromiseWrapper(instance.post("/member-directory/", data));
}

export function updateMemberDirectoryRequest(id, data) {
  return requestPromiseWrapper(instance.patch(`/member-directory/${id}/`, data));
}

export function deleteMemberDirectoryRequest(id) {
  return requestPromiseWrapper(instance.delete(`/member-directory/${id}/`));
}

export function testMemberDirectoryConnectionRequest(id) {
  return showFetching(logoutIfTokenIsInvalid(instance.get(`member-directory/${id}/test_connection/`)));
}

export function createCampaignRequest(data) {
  return requestPromiseWrapper(instance.post("/campaign/", data));
}

export function getCampaignTemplatesRequest(id, data) {
  return requestPromiseWrapper(instance.get(`/campaign/${id}/update_templates/`, data));
}

export function updatePartialCampaignRequest(id, data) {
  return requestPromiseWrapper(instance.patch(`/campaign/${id}/`, data));
}

export function fetchCampaignTemplatesRequest(id, templateId) {
  return requestPromiseWrapper(instance.post(`campaign/${id}/fetch_template/`, { template_id: templateId }));
}

export function deleteCampaignRequest(id) {
  return requestPromiseWrapper(instance.delete(`/campaign/${id}/`));
}

export function readCampaignRequest(id) {
  return requestPromiseWrapper(instance.get(`/campaign/${id}/`));
}

export function createCompanyInvitationRequest(data) {
  return requestPromiseWrapper(instance.post("/company-settings/invitations/", data));
}

export function authRequest(email, password) {
  delete instance.defaults.headers.common["Authorization"];
  return showFetching(
    instance.post(
      "/auth",
      {
        email,
        password,
      },
      {
        headers: { Authorization: null },
      }
    )
  );
}

export function logoutRequest() {
  return requestPromiseWrapper(instance.get("/logout"));
}

export function newPasswordRequest(email, token, password) {
  return showFetching(
    instance.post("/new-password", {
      email,
      token,
      password,
    })
  )
    .then(() =>
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t("notification:message.updated.password", "Password changed successfully."),
        "success"
      )
    )
    .catch(() =>
      showNotification(
        i18next.t("notification:title.error", "Something went wrong!"),
        i18next.t("notification:message.error.token", "Your token has expired."),
        "danger"
      )
    );
}

export function companySignUpRequest(name) {
  return showFetching(
    alertErrors(
      instance.post("/company/", {
        name,
      })
    )
  );
}

export function restorePasswordRequest(email) {
  return showFetching(
    alertErrors(
      instance.post("/restore-password", {
        email,
      })
    )
  ).then(
    () =>
      showNotification(
        i18next.t("notification:title.success", "Success!"),
        i18next.t(
          "notification:message.recovery.password",
          "Password recovery instructions have been sent to your email."
        ),
        "success"
      ),
    () =>
      showNotification(
        i18next.t("notification:title.error", "Error!"),
        i18next.t("notification:message.error.notExistingEmail", "User with this email does not exist."),
        "danger"
      )
  );
}

export function getProfileInfoRequest() {
  return requestPromiseWrapper(instance.get("/members/me"));
}

export function saveProfileInfoRequest(profileInfo) {
  return requestPromiseWrapper(instance.patch("/members/me", profileInfo));
}

export function changeCurrentUserAvatarRequest(avatar) {
  const formData = new FormData();
  formData.append("avatar", avatar);
  return requestPromiseWrapper(
    instance.patch("/members/me", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  );
}

export function getTenantInfoRequest(domain = null) {
  return instance.get(`/domain/${domain ? `?domain=${domain}` : ""}`, { headers: { Authorization: null } }).then(
    (result) => {
      return {
        isPublicTenant: !!result.data.is_public_tenant,
        isExist: true,
        error: null,
        hasAdmin: !!result.data.has_admin,
      };
    },
    (error) => {
      return {
        isPublicTenant: false,
        isExist: false,
        error: error.response || { status: 418, statusText: error.message },
      };
    }
  );
}

export function userSignUpRequest(userData) {
  return requestPromiseWrapper(instance.post("/signup", userData));
}
