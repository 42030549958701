import {store} from "../store";
import {showNotificationAction} from "../store/actions/notification";
import {hideFetchingAction, showFetchingAction} from "../store/actions/global";
import {logoutAction} from "../store/actions/auth";
import history from "../history";
import {showModalWindowAction} from "../store/actions/modal";

export function showFetching(promise) {
  store.dispatch(showFetchingAction());
  return promise.finally(() => {
    store.dispatch(hideFetchingAction());
  });
}

export function showNotification(title, message, type = "info") {
  store.dispatch(showNotificationAction(title, message, type));
}

export function alertErrors(promise, title = "An error has occurred!") {
  return promise.catch(error => {
    const errorMessage = error.response && typeof error.response.data === "object"
      ?
      Object.values(error.response.data).join(" \n")
      :
      error.message;
    showNotification(title, errorMessage, "danger");
    throw error;
  });
}

export function logoutIfTokenIsInvalid(promise) {
  return promise.catch(error => {
    if (error.response && error.response.data.detail === "Недопустимый токен.") {
      const currentPath = history.location.pathname;
      store.dispatch(logoutAction(true));
      history.push(`/login?redirect=${currentPath}`);
    }
    throw error;
  });
}

export const showModalWindow = {
  alert: function alert(title, message, color = "info", callbackOK = null) {
    store.dispatch(showModalWindowAction(title, message, color, "alert", callbackOK, null));
  },
  confirm: function confirm(title, message, color = "info", callbackOK = null, callbackCancel = null) {
    store.dispatch(showModalWindowAction(title, message, color, "confirm", callbackOK, callbackCancel));
  }
};