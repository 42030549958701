import React, { useState, useEffect } from "react";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { getCampaignRequest } from "../../services/api";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import CampaignsTable from "./CampaignsTable";
import CampaignCreateForm from "./CampaignCreateForm";
import { useTranslation } from "react-i18next";

const Campaign = () => {
  const [campaigns, setCampaigns] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getCampaignRequest().then(({ data }) => setCampaigns(data));
  }, []);


  return (
    <CCard>
      <CCardHeader>
        <h1>{t("page.campaigns", "Campaigns")}</h1>
      </CCardHeader>
      <CCardBody>
        <CampaignCreateForm setCampaigns={setCampaigns} />
        <CampaignsTable setCampaigns={setCampaigns} campaigns={campaigns} />
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(Campaign);
