import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from "@coreui/react";
import ARIcon from "../../../../ARIcon";
import {Link} from "react-router-dom";
import {logoutAction} from "../../../../../store/actions/auth";
import {useActions} from "../../../../../store/actions";
import {useSelector} from "react-redux";
import {showModalWindow} from "../../../../../utils/actionWrappers";
import {useTranslation} from "react-i18next";

const HeaderProfileDropdown = (props) => {
  const {t} = useTranslation();
  const avatarPath = useSelector(state => state.auth.profile.avatarPath);
  const fullName = useSelector(state => `${state.auth.profile.firstName} ${state.auth.profile.lastName}`);
  const logout = useActions(logoutAction);
  const onLogoutClick = () => {
    showModalWindow.confirm(
      t("modal:title.confirm","Confirm your action"),
      t("modal:message.logout", "Are you sure you want to log out of your account?"),
      "danger",
      () => logout(true));
  };

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <span className="mr-3">{fullName}</span>
        <div className="c-avatar">
          <CImg
            src={avatarPath}
            className="c-avatar-img"
            alt="You"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>{t("header.avatarDropdown.title", "Account")}</strong>
        </CDropdownItem>
        <CDropdownItem className="mt-0" divider/>
        <CDropdownItem tag={Link} to="/profile">
          <ARIcon name="cilUser" className="mfe-2"/>
          {t("header.avatarDropdown.profile", "Profile")}
        </CDropdownItem>
        <CDropdownItem divider/>
        <CDropdownItem onClick={onLogoutClick}>
          <ARIcon name="cilAccountLogout" className="mfe-2"/>
          {t("header.avatarDropdown.logout", "Logout")}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderProfileDropdown;