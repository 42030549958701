import React, { useState, useMemo } from "react";
import { CDataTable, CButton, CJumbotron } from "@coreui/react";
import {
  getTableColumnHeaders,
  getTableFilterText,
  getItemsPerPageSelectValues,
  getCampaignList,
} from "./Audiences.model";
import { Link } from "react-router-dom";
import { deleteAudienceRequest, getCampaignRequest } from "../../services/api";
import { showModalWindow } from "../../utils/actionWrappers";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { getFilteredItems, getTableNoItemsView } from "../../utils/index.js";
import AudiencesRemoveModal from "./AudiencesRemoveModal";
import { useTranslation } from "react-i18next";
import { CampaignTypes } from "../../models/Campaign.model";

const AudiencesTable = ({ audiences, setAudiences }) => {
  const [tableFilterValue, setTableFilterValue] = useState("");
  const [campaigns, setCampaigns] = useState(null);
  const { t } = useTranslation();

  const deleteAudience = (id) => {
    getCampaignRequest().then(({ data }) => {
      const campaings = data.map((item) => {
        const type = item.timeout ? CampaignTypes.Auto : item.planned_at ? CampaignTypes.Planned : CampaignTypes.Flash;
        return { ...item, type };
      });
      const campaignsWithCurrentAudience = getCampaignList(id, campaings);
      if (campaignsWithCurrentAudience.length === 0) {
        deleteAudienceRequest(id).then(() => setAudiences((state) => state.filter((item) => item.id !== id)));
      } else {
        setCampaigns(campaignsWithCurrentAudience);
      }
    });
  };

  const onRemoveClick = ({ name, id }) => {
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t("modal:message.delete.audience_withItemName", `Are you sure you want to delete audience ${name} ?`, {
        itemName: name,
      }),
      "info",
      () => deleteAudience(id)
    );
  };

  const filteredAudiences = useMemo(() => getFilteredItems(audiences, tableFilterValue, ["name"]), [
    audiences,
    tableFilterValue,
  ]);

  if (audiences.length === 0) {
    return (
      <CJumbotron>
        <h3 className="text-center">{t("emptyList.audiences", "There will be a list of your audiences here")}</h3>
      </CJumbotron>
    );
  }

  return (
    <>
      <AudiencesRemoveModal toggleModal={() => setCampaigns(null)} campaigns={campaigns} />
      <CDataTable
        items={filteredAudiences}
        fields={getTableColumnHeaders()}
        hover

        responsive
        itemsPerPageSelect={getItemsPerPageSelectValues()}
        itemsPerPage={10}
        onTableFilterChange={setTableFilterValue}
        tableFilterValue={tableFilterValue}
        pagination
        noItemsView={getTableNoItemsView()}
        tableFilter={getTableFilterText()}
        clickableRows
        scopedSlots={{
          name: (item) => (
            <td>
              <Link to={`/audience/${item.id}`}>{item.name}</Link>
            </td>
          ),
          totalSubscribers: ({ total_subscribers }) => <td>{total_subscribers}</td>,
          remove: (item) => (
            <td className="">
              <CButton onClick={() => onRemoveClick(item)} color="danger" size="sm">
                {t("button.remove", "Remove")}
              </CButton>
            </td>
          ),
        }}
      />
    </>
  );
};

export default withErrorBoundary(AudiencesTable);
