import i18next from "../services/language/i18next";

export function checkValidCharactersForSubdomain(value) {
  return /^[A-Za-z0-9]*$/.test(value);
}

export function checkValidSubdomain(value) {
  return /^[A-Za-z0-9]{2,63}$/.test(value);
}

export const phoneCharactersPattern = "[0-9\\+]*";
export const emailCharactersPattern = "^[a-zA-Z0-9_\\.\\+\\-@]*$";
export const emailValidation = /^\S+@\S+$/i;

export function createFormInitialState(fields) {
  return fields.reduce((initialState, fieldName) => {
    initialState[fieldName] = {
      value: "",
      isValid: null,
      showValidationRules: false,
    };

    return initialState;
  }, {});
}

export const getTime = (date) => {
  if (!date) return "";
  const getTwoDigits = (value) => (value < 10 ? `0${value}` : value);
  const formatTime = (date) => {
    const hours = getTwoDigits(date.getHours());
    const mins = getTwoDigits(date.getMinutes());

    return `${hours}:${mins}`;
  };

  return formatTime(new Date(date));
};

export const getDate = (date) => {
  if (!date) return "";
  const getTwoDigits = (value) => (value < 10 ? `0${value}` : value);
  const formatDate = (date) => {
    const day = getTwoDigits(date.getDate());
    const month = getTwoDigits(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return formatDate(new Date(date));
};

export const getTimeout = (str) => {
  if (!str) return { timeoutValue: 0, timeoutUnits: "minutes" };

  const getUnitsAndValues = (number) => {
    if (number === 0) return { timeoutValue: 0, timeoutUnits: "minutes" };
    if (number % (24 * 60 * 60) === 0) return { timeoutValue: number / (24 * 60 * 60), timeoutUnits: "days" };
    if (number % (60 * 60) === 0) return { timeoutValue: number / (60 * 60), timeoutUnits: "hours" };
    return { timeoutValue: number / 60, timeoutUnits: "minutes" };
  };

  const seconds = str.split(" ").reduce((acc, item) => {
    if (Number(item)) return item * 24 * 60 * 60 + acc;
    return (
      acc +
      item.split(":").reduce((acc, hms, i) => {
        return acc + hms * Math.pow(60, hms.length - i);
      }, 0)
    );
  }, 0);

  return getUnitsAndValues(seconds);
};

export const getFilteredItems = (items, tableFilterValue, itemsDataColumns) => {
  const filter = tableFilterValue.toLowerCase();
  const valueContainFilter = (val) => String(val).toLowerCase().includes(filter);
  return items.filter((item) => !!itemsDataColumns.find((key) => valueContainFilter(item[key])));
};


export function getTableNoItemsView() {
  return (
    {
      noResults: i18next.t("noData.table.noResults", "No filtering results"),
      noItems: i18next.t("noData.table.noItems", "No items")
    }
  );
}