import React from "react";
import ARIcon from "../../../ARIcon/ARIcon";
import i18next from "i18next";

const getNavigation = (isStaff) => {
  const navigation = [
    {
      forStaffOnly: false,
      item: {
        _tag: "CSidebarNavItem",
        name: i18next.t("sidebar.audiences", "Audiences"),
        to: "/audiences",
        icon: <ARIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
      },
    },
    {
      forStaffOnly: false,
      item: {
        _tag: "CSidebarNavItem",
        name: i18next.t("sidebar.campaigns", "Campaigns"),
        to: "/campaigns",
        icon: <ARIcon name="cilBullhorn" customClasses="c-sidebar-nav-icon" />,
      },
    },
    {
      forStaffOnly: true,
      item: {
        _tag: "CSidebarNavDropdown",
        name: i18next.t("sidebar.members", "Members"),
        icon: <ARIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
        _children: [
          {
            _tag: "CSidebarNavItem",
            name: i18next.t("sidebar.membersChildren.list", "List"),
            to: "/members",
          },
          {
            _tag: "CSidebarNavItem",
            name: i18next.t("sidebar.membersChildren.directories", "Directories"),
            to: "/directories",
          },
        ],
      },
    },
    {
      forStaffOnly: false,
      item: {
        _tag: "CSidebarNavItem",
        name: i18next.t("sidebar.faq", "FAQ"),
        to: "/faq",
        icon: <ARIcon name="cilInfo" customClasses="c-sidebar-nav-icon" />,
      },
    },
    {
      forStaffOnly: false,
      item: {
        _tag: "CSidebarNavItem",
        name: i18next.t("sidebar.settings", "Settings"),
        to: "/settings",
        icon: <ARIcon name="cilInfo" customClasses="c-sidebar-nav-icon" />,
      },
    },
  ];

  return navigation.filter((item) => (isStaff ? true : item.forStaffOnly !== true)).map(({item}) => item);
};

export default getNavigation;
