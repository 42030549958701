export const AUTH = {
  LOGIN_SUCCESS: "AUTH/LOGIN_SUCCESS",
  LOGOUT_SUCCESS: "AUTH/LOGOUT_SUCCESS",
  SIGN_UP_SUCCESS: "AUTH/SIGN_UP_SUCCESS",
  LOGIN_FAILURE: "AUTH/LOGIN_FAILURE",
  SIGN_UP_FAILURE: "AUTH/SIGN_UP_FAILURE",
  UPDATE_PROFILE: "AUTH/UPDATE_PROFILE"
};

export const GLOBAL = {
  FETCHING: "GLOBAL/FETCHING",
  FETCHED: "GLOBAL/FETCHED",
  SET_TENANT: "GLOBAL/SET_TENANT",
  SET_HAS_ADMIN: "GLOBAL/SET_HAS_ADMIN"
};

export const NOTIFICATION = {
  SHOW: "NOTIFICATION/SHOW",
  HIDE: "NOTIFICATION/HIDE",
  CLEAR: "NOTIFICATION/CLEAR"
};

export const MODAL_WINDOW = {
  SHOW: "MODAL_WINDOW/SHOW",
  HIDE: "MODAL_WINDOW/HIDE",
};
