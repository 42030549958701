import React, { useEffect } from "react";
import "./App.scss";
import AuthorizedPageWrapper from "./components/PageWrappers/AuthorizedPageWrapper";
import UnAuthorizedPageWrapper from "./components/PageWrappers/UnAuthorizedPageWrapper";
import { useSelector } from "react-redux";
import Spinner from "./components/Spinner/Spinner";
import Notification from "./components/Notification";
import { useActions } from "./store/actions";
import { setTenantAction } from "./store/actions/global";
import ModalWindow from "./components/ModalWindow/ModalWindow";
import { withErrorBoundary } from "./components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { ns } from "./services/language/i18next";

function App() {
  const {isFetching, requestsNum, tenant} = useSelector(state => state.global);
  const { ready } = useTranslation(ns, { useSuspense: false });
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const setTenant = useActions(setTenantAction);

  useEffect(() => {
    setTenant();
  }, [setTenant]);

  const showContent = tenant.isExist !== null && ready;

  return (
    <>
      <Spinner show={!showContent || isFetching} fullScreen={true} timeout={50} requestsNum={requestsNum} />
      {showContent && (
        <>
          <ModalWindow />
          <Notification timeout={10} />
          {isAuthorized ? <AuthorizedPageWrapper /> : <UnAuthorizedPageWrapper />}
        </>
      )}
    </>
  );
}

export default withErrorBoundary(App);
