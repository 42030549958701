import React, { useState } from "react";
import { CButton, CCol, CForm, CInput, CRow } from "@coreui/react";
import { createCampaignRequest } from "../../services/api";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const CampaignCreateForm = ({ setCampaigns }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [inputValues, setInputValues] = useState({
    value: "",
    isValid: null,
  });

  const onInputChange = (e) => {
    setInputValues({
      value: e.target.value,
      isValid: e.target.validity.valid,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    createCampaignRequest({ name: inputValues.value }).then((response) => {
      if (response) {
        const {
          data: { id },
        } = response;
        history.push(`/campaigns/${id}/edit`);
      }
    });
  };

  return (
    <CForm onSubmit={onFormSubmit}>
      <CRow className="mb-3">
        <CCol xs="6">
          <CInput
            value={inputValues.value}
            onChange={onInputChange}
            type="text"
            id="audience"
            name="audience"
            autoComplete="name"
            invalid={inputValues.isValid === false}
            required
          />
        </CCol>
        <CCol xs="6">
          <CButton disabled={!inputValues.isValid} type="submit" color="primary" className="px-4">
            {t("form:button.create", "Create")}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default withErrorBoundary(CampaignCreateForm);
