import React, { useState, useMemo, useEffect } from "react";
import { CDataTable, CButton, CJumbotron } from "@coreui/react";
import { showModalWindow } from "../../utils/actionWrappers";
import { updatePartialAudienceRequest } from "../../services/api/index.js";
import AddressEditModalWindow from "./AddressEditModalWindow/AddressEditModalWindow";
import { getTableFilterText, getTableColumnHeaders, getItemsPerPageSelectValues } from "./AudienceTable.model";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { getFilteredItems, getTableNoItemsView } from "../../utils/index";

const AudienceTable = ({ audience: { id, addresses }, setAudience }) => {
  const [tableFilterValue, setTableFilterValue] = useState("");
  const [showAddressEditModal, setShowAddressEditModal] = useState(false);
  const [address, setAddress] = useState("");
  const [showEmptyJumbotron, setShowEmptyJumbotron] = useState(true);
  const { t } = useTranslation();

  const addressEditModalToggle = () => {
    setShowAddressEditModal(!showAddressEditModal);
  };

  const deleteAddress = (addressId) => {
    const updatedAddresses = addresses.filter((item) => item.id !== addressId);
    updatePartialAudienceRequest(id, { addresses: updatedAddresses }).then(({ data }) => setAudience(data));
  };

  const renameAddress = (updatedAddress) => {
    const updatedAddresses = addresses.map((item) =>
      updatedAddress.id !== item.id ? item : { ...address, ...updatedAddress }
    );
    updatePartialAudienceRequest(id, { addresses: updatedAddresses }).then(({ data }) => {
      setAudience(data);
    });
  };

  const onButtonEditClick = (address) => {
    setAddress(address);
    addressEditModalToggle();
  };

  const onButtonRemoveClick = ({ id, email, first_name, last_name }) => {
    const name = first_name ? `${first_name} ${last_name}` : email;
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t("modal:message.delete.subscriber_withItemName", `Are you sure you want to delete the subscriber ${name}?`, {
        itemName: name,
      }),
      "danger",
      () => deleteAddress(id)
    );
  };

  const filteredAddresses = useMemo(
    () => getFilteredItems(addresses, tableFilterValue, ["email", "first_name", "last_name"]),
    [addresses, tableFilterValue]
  );

  useEffect(() => {
    setShowEmptyJumbotron(filteredAddresses.length === 0);
  }, [filteredAddresses]);

  if (showEmptyJumbotron) {
    return (
      <CJumbotron>
        <h3 className="text-center">
          {t("emptyList.subscribers", "There will be a list of your subscribers here111")}
        </h3>
      </CJumbotron>
    );
  }

  return (
    <>
      <CDataTable
        items={filteredAddresses}
        fields={getTableColumnHeaders()}
        hover
        tableFilter={getTableFilterText()}
        onTableFilterChange={setTableFilterValue}
        tableFilterValue={tableFilterValue}
        sorter
        responsive
        itemsPerPage={10}
        noItemsView={getTableNoItemsView()}
        itemsPerPageSelect={getItemsPerPageSelectValues()}
        pagination
        scopedSlots={{
          first_name: ({ first_name }) => <td>{first_name}</td>,
          last_name: ({ last_name }) => <td>{last_name}</td>,
          edit: (item) => (
            <td>
              <CButton onClick={() => onButtonEditClick(item)} color="success" size="sm">
                {t("button.edit", "Edit")}
              </CButton>
            </td>
          ),
          remove: (item) => (
            <td>
              <CButton onClick={() => onButtonRemoveClick(item)} color="danger" size="sm">
                {t("button.remove", "Remove")}
              </CButton>
            </td>
          ),
        }}
      ></CDataTable>
      {showAddressEditModal && (
        <AddressEditModalWindow renameAddress={renameAddress} address={address} onHide={addressEditModalToggle} />
      )}
    </>
  );
};

export default withErrorBoundary(AudienceTable);
