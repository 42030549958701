import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel,
  CRow
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import {companySignUpRequest, getTenantInfoRequest} from "../../services/api";
import {checkValidCharactersForSubdomain, checkValidSubdomain} from "../../utils";
import {useQuery} from "../../utils/useQuery";
import {DOMAIN, PORT, PROTOCOL} from "../../utils/constants";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {Trans, useTranslation} from "react-i18next";

const agreeWithTermsInitialState = {
  value: false,
  showValidationRules: false
};

const SignUp = (props) => {
  const query = useQuery();
  const querySubdomain = query.get("subdomain");
  const queryIsSubdomainExist = query.get("isExist") !== "false";
  const subdomainInitialState = {
    value: querySubdomain ? querySubdomain : "",
    isExist: null,
    timeout: null,
    isValid: querySubdomain ? true : null,
    showValidationRules: false,
  };
  const [subdomain, setSubdomain] = useState(subdomainInitialState);
  const [agreeWithTerms, setAgreeWithTerms] = useState(agreeWithTermsInitialState);
  const {t} = useTranslation();

  const onSubdomainInput = (event) => {
    if (subdomain.timeout) clearTimeout(subdomain.timeout);
    if (checkValidCharactersForSubdomain(event.target.value)) {
      const isValid = checkValidSubdomain(event.target.value);
      const value = event.target.value.toLowerCase();
      const timeout = isValid ? setTimeout(() => {
        getTenantInfoRequest(`${value}.${DOMAIN}`).then((response) => {
          setSubdomain((oldState) => {
            return {
              ...oldState,
              isExist: response.isExist
            };
          });
        });
      }, 400) : null;
      setSubdomain((oldState) => {
        return {
          ...oldState,
          value,
          isValid,
          showValidationRules: false,
          isExist: null,
          timeout
        };
      });

    } else {
      setSubdomain((oldState) => ({
        ...oldState,
        showValidationRules: true,
      }));
    }
  };

  const onAgreeWithTermsChanged = (event) => {
    setAgreeWithTerms({
      value: event.target.checked,
      showValidationRules: !event.target.checked
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    if (agreeWithTerms.value) {
      companySignUpRequest(subdomain.value).then(() => {
        window.location.assign(`${PROTOCOL}//${subdomain.value}.${DOMAIN}${PORT ? `:${PORT}` : ""}/sign_up_user`);
      }).catch(error => {
        if (error.response && error.response.status === 500) {
          setAgreeWithTerms(agreeWithTermsInitialState);
          setSubdomain(subdomainInitialState);
        }
      });
    } else {
      setAgreeWithTerms((oldState) => ({
        ...oldState,
        showValidationRules: !oldState.value
      }));
    }
  };

  return (
    <>
      <ARIcon name={"cilBolt"} size={"3xl"}/>
      <CForm onSubmit={onFormSubmit}>
        <h1>Lets Start</h1>
        <p className="text-muted">{t("card.description.register", "Register your company to use the service")}</p>

        <CAlert color="danger" show={!queryIsSubdomainExist}>
          The domain <b>{querySubdomain}.${`.${DOMAIN}`}</b> does not exist.
          Perhaps you misspelled the page address.
          But you can register this domain for your company.
        </CAlert>

        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilLink"}/>
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput type="text"
                  className="text-right"
                  value={subdomain.value}
                  onInput={onSubdomainInput}
                  valid={subdomain.isExist === false && subdomain.isValid}
                  invalid={subdomain.isExist || subdomain.isValid === false}
                  required
                  autoFocus
                  placeholder={t("form:placeholder.subdomain", "subdomain")}
          />
          <CInputGroupAppend>
            <CInputGroupText>
              {`.${DOMAIN}`}
            </CInputGroupText>
          </CInputGroupAppend>
          {subdomain.showValidationRules && (
            <div className="invalid-feedback invalid-feedback_show">
              {t("form:error.domain.characters", "The company domain must contain only lowercase letters or numbers!")}
            </div>
          )}
          {subdomain.isExist && (
            <CInvalidFeedback>
              {t("form:error.domain.exist", "A company with this domain already exists!")}
            </CInvalidFeedback>
          )}
        </CInputGroup>
        <CFormGroup className="ml-4">

          <CInputCheckbox id="service_checkbox"
                          checked={agreeWithTerms.value}
                          onChange={onAgreeWithTermsChanged}
                          invalid={!agreeWithTerms.value}/>

          <CLabel htmlFor="service_checkbox" className="mb-0">
            <Trans i18nKey="form:label.terms">
              I have read and agree to the <Link to="/#">terms of the service</Link>.
            </Trans>
          </CLabel>
          {agreeWithTerms.showValidationRules &&
          <CInvalidFeedback>{t("form:error.terms.value", "You must agree to the terms of service!")}</CInvalidFeedback>}
        </CFormGroup>

        <CFormGroup className="w-100 text-center">

        </CFormGroup>
        <CRow>
          <CCol xs="6">
            <CButton className="px-4" type="submit" color="primary"
                     disabled={!subdomain.isValid || !!subdomain.isExist}>
              {t("button.register", "Register")}
            </CButton>
          </CCol>
          <CCol xs="6" className="text-right">
            <CButton tag={Link} to="/login_sub" color="link" className="px-0">
            {t("button.loginWithRegisteredDomain", "Sign in with registered domain")}
            </CButton>
          </CCol>
        </CRow>

      </CForm>
    </>
  );
};

export default withErrorBoundary(SignUp);