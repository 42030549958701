import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "../../../components/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { showNotification } from "../../../utils/actionWrappers";
import { deleteMembersRequest } from "../../../services/api";
import { showModalWindow } from "../../../utils/actionWrappers";
import { readMembersRequest, updateMembersRequest } from "../../../services/api";
import Member from "../Member";
import { useTranslation } from "react-i18next";

const MemberDetails = () => {
  const history = useHistory();
  const { id } = useParams();
  const [memberData, setMemberData] = useState({});
  const [memberName, setMemberName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    readMembersRequest(id).then(({ data }) => {
      setMemberName(data.first_name);
      setMemberData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMember = (values) => {
    updateMembersRequest(id, values).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.updated.member", "Member has been updated"),
        "success"
      );
      history.push("/members");
    });
  };

  const removeMember = () => {
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t("modal:message.delete.member", "Are you sure you want to delete this member"),
      "warning",
      () => {
        deleteMembersRequest(id).then(() => {
          showNotification(
            t("notification:title.success", "Success!"),
            t("notification:message.deleted.member", "Member has been deleted"),
            "success"
          );
          history.push("/members");
        });
      }
    );
  };

  return (
    <Member
      isMemberExist={true}
      name={memberName}
      onRemove={removeMember}
      onSave={updateMember}
      initialData={memberData}
      isMemberEditable = {memberData.member_directory === null}
    />
  );
};

export default withErrorBoundary(MemberDetails);
