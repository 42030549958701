import React, { useState, useMemo } from "react";
import { CDataTable, CButton, CJumbotron, CBadge } from "@coreui/react";
import { getTableColumnHeaders, getTableFilterText, getItemsPerPageSelectValues } from "./Campaigns.model";
import { Link } from "react-router-dom";
import { deleteCampaignRequest } from "../../services/api";
import { showModalWindow } from "../../utils/actionWrappers";
import { getFilteredItems, getTableNoItemsView } from "../../utils/index";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import "./CampaignsTable.scss";

const CampaignsTable = ({ campaigns, setCampaigns }) => {
  const { t } = useTranslation();
  const [tableFilterValue, setTableFilterValue] = useState("");

  const deleteCampaign = (id) => {
    deleteCampaignRequest(id).then(() => setCampaigns((state) => state.filter((item) => item.id !== id)));
  };

  const getCampaignStatus = (isActive) => {
    const [status, color] = isActive
      ? [t("text.campaignDetails.active", "Active"), "success"]
      : [t("text.campaignDetails.paused", "Paused"), "warning"];
    return (
      <div className="campaignsTable__status">
        <span className={`campaignsTable__status-badge badge bg-${color}`} />
        <span>{status}</span>
      </div>
    );
  };

  const onRemoveClick = ({ name, id }) => {
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t("modal:message.delete.campaign_withItemName", `Are you sure you want to delete campaign ${name} ?`, {
        itemName: name,
      }),
      "info",
      () => deleteCampaign(id)
    );
  };

  const filteredCampaigns = useMemo(
    () => getFilteredItems(campaigns, tableFilterValue, ["name"]),
    [campaigns, tableFilterValue]
  );

  if (campaigns.length === 0) {
    return (
      <CJumbotron>
        <h3 className="text-center">
          {t("emptyList.campaigns", "There will be a list of your campaigns here")}
        </h3>
      </CJumbotron>
    );
  }

  return (
    <CDataTable
      items={filteredCampaigns}
      fields={getTableColumnHeaders()}
      hover
      tableFilter={getTableFilterText()}
      onTableFilterChange={setTableFilterValue}
      tableFilterValue={tableFilterValue}
      sorter
      responsive
      itemsPerPageSelect={getItemsPerPageSelectValues()}
      itemsPerPage={10}
      noItemsView={getTableNoItemsView()}
      pagination
      scopedSlots={{
        name: (item) => (
          <td className="CampaignsTable__name">
            <Link to={`/campaigns/${item.id}/details`}>{item.name}</Link>
            {item.is_draft && <CBadge color="secondary"> {t("table:draft", "draft")} </CBadge>}
          </td>
        ),
        status: ({ timeout, is_active, is_draft }) => {
          return <td>{!is_draft && timeout && getCampaignStatus(is_active)}</td>;
        },
        sent_at: ({ sent_at }) => <td>{sent_at}</td>,
        planned_at: ({ sent_at, planned_at, is_draft }) => <td>{!sent_at && !is_draft && planned_at}</td>,
        statistics: ({ id, total_sent }) => (
          <td>
            {!!total_sent && (
              <CButton tag={Link} to={`/campaigns/${id}/details#statistic`} color="primary" size="sm">
                {t("button.statistics", "Statistics")}
              </CButton>
            )}
          </td>
        ),
        remove: (item) => (
          <td className="">
            <CButton onClick={() => onRemoveClick(item)} color="danger" size="sm">
              {t("button.remove", "Remove")}
            </CButton>
          </td>
        ),
      }}
    />
  );
};

export default withErrorBoundary(CampaignsTable);
