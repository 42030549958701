import React from "react";
import {
  CButton,
  CModal,
  CInvalidFeedback,
  CCol,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CForm,
  CInput,
  CFormGroup,
  CLabel,
} from "@coreui/react";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation, Trans } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

const SenderEditModalWindow = ({ onHide, sender, renameSender }) => {
  const { t } = useTranslation();

  const senderForm = useFormik({
    initialValues: {
      name: sender.name || "",
      email: sender.email || "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      name: yup.string().required(t("form:error.required", "This field is required!")),
      email: yup
        .string()
        .required(t("form:error.required", "This field is required!"))
        .email(
          <Trans i18nKey="form:error.email.value">
            You must enter your email in the format <b>user@domain.com</b>!
          </Trans>
        ),
    }),
    onSubmit: ({ email, name }) => {
      renameSender({ ...sender, name, email });
      onHide();
    },
  });

  return (
    <CModal centered onClose={onHide} color="info" show>
      <CModalHeader closeButton>{t("modal:title.sender", "Cancel")}</CModalHeader>
      <CModalBody>
        <CForm id="sender-form" onSubmit={senderForm.handleSubmit}>
          <CFormGroup row>
            <CCol md="3" className="align-items-center d-flex">
              <CLabel className="mb-0" htmlFor="name">
                {t("form:label.firstName", "First name")}
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="name"
                name="name"
                placeholder={t("form:placeholder.firstName", "Enter first name")}
                value={senderForm.values.name}
                onChange={senderForm.handleChange}
                invalid={!!senderForm.errors.name}
              />
              <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3" className="align-items-center d-flex">
              <CLabel className="mb-0" htmlFor="address_form-email">
                {t("form:label.email", "First email")}
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="address_form-email"
                name="email"
                placeholder={t("form:placeholder.email", "Enter first email")}
                value={senderForm.values.email}
                onChange={senderForm.handleChange}
                invalid={!!senderForm.errors.email}
              />
              <CInvalidFeedback>{senderForm.errors.email}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton onClick={onHide} color="secondary">
          {t("form:button.cancel", "Cancel")}
        </CButton>
        <CButton form="sender-form" type="submit" color="info">
          {t("form:button.save", "Save")}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default withErrorBoundary(SenderEditModalWindow);
