import React from "react";
import { campaignTypeMapping } from "../../../models/Campaign.model";
import { CampaignTypes } from "../../../models/Campaign.model";

const CampaignStatus = ({ campaign }) => {
  if (!campaign.type) return null;

  const text = campaignTypeMapping[campaign.type].statusText(campaign);
  const color = campaign.is_active ? "success" : "warning";

  return (
    <>
      {campaign.type === CampaignTypes.Auto && <span className={`statusBadge badge bg-${color}`} />}
      <span>{text}</span>
    </>
  );
};

export default React.memo(CampaignStatus);
