import React, { useState, useEffect } from "react";
import "./Campaign.scss";
import { CCard, CCardBody, CCardHeader, CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react";
import {
  getAudienceRequest,
  readCampaignRequest,
  updatePartialCampaignRequest,
  getSendersRequest,
} from "../../services/api";
import { useParams, useLocation } from "react-router-dom";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import CampaignDetails from "./CampaignDetails";
import CampaignStatistics from "./CampaignStatistics";
import { useTranslation } from "react-i18next";
import { CampaignTypes } from "../../models/Campaign.model";

const getItem = (list, id) => id ? _.find(list, { id }) : "";

const CampaignExact = () => {
  const [campaign, setCampaign] = useState({});
  const { id: campaignId } = useParams();
  const { hash } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const updateCampaignAutomailStatues = (data) => {
    updatePartialCampaignRequest(campaignId, data).then(({ data }) => {
      setCampaign((state) => ({ ...state, is_active: data.is_active }));
    });
  };

  const updateCampaignTimeout = ({ timeoutUnits, timeoutValue }) => {
    const unitsMapping = {
      minutes: (value) => Number(value) * 60,
      hours: (value) => Number(value) * 60 * 60,
      days: (value) => Number(value) * 60 * 60 * 24,
    };

    const timeout = unitsMapping[timeoutUnits](timeoutValue);
    updatePartialCampaignRequest(campaignId, { timeout }).then(({ data }) => {
      setCampaign((state) => ({ ...state, timeout: data.timeout }));
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: campaign } = await readCampaignRequest(campaignId);
      if (campaign.is_draft) {
        history.push(`/campaigns/${campaignId}/edit`);
      } else {
        const { data: audiences } = await getAudienceRequest();
        const { data: senders } = await getSendersRequest();
        const audienceName = getItem(audiences, campaign.audience).name;
        const sender = getItem(senders, campaign.sender);
        const type = campaign.timeout
          ? CampaignTypes.Auto
          : campaign.planned_at
          ? CampaignTypes.Planned
          : CampaignTypes.Flash;
        setCampaign({ ...campaign, audienceName, type, sender });
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CCard>
      <CCardHeader>
        <h1>{campaign?.name}</h1>
      </CCardHeader>
      <CCardBody>
        <CTabs activeTab={hash || "#home"}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink data-tab="#home">{t("text.campaignDetails.campaignDetails", "Campaign Details")}</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="#statistic">
                {t("text.campaignDetails.campaignStatistics", "Campaign Statistics")}
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane data-tab="#home">
              <CampaignDetails
                campaign={campaign}
                updateCampaignTimeout={updateCampaignTimeout}
                updateCampaignAutomailStatues={updateCampaignAutomailStatues}
              />
            </CTabPane>
            <CTabPane data-tab="#statistic">
              <CampaignStatistics campaign={campaign} />
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(CampaignExact);
