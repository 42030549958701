import i18next from "../../services/language/i18next";

const getTableColumnHeaders = () => [
  { key: "email", label: i18next.t("table:header.email", "Email"), _style: { width: "20%" } },
  { key: "first_name", label: i18next.t("table:header.firstName", "First name"), _style: { width: "20%" } },
  { key: "last_name", label: i18next.t("table:header.lastName", "Last name"), _style: { width: "20%" } },
  { key: "date_added", label: i18next.t("table:header.dateAdded", "Date Added"), _style: { width: "30%" } },
  { key: "edit", label: "", _style: { width: "10%" }, sorter: false, filter: false },
  { key: "remove", label: "", _style: { width: "10%" }, sorter: false, filter: false },
];

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [10, 50, 100],
});

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label"),
  placeholder: i18next.t("table:filter.placeholder.members", "Search for members"),
  external: true,
});

export { getTableColumnHeaders, getTableFilterText, getItemsPerPageSelectValues };
