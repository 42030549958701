import i18next from "../services/language/i18next";

export const CampaignTypes = {
  Flash: "flash",
  Planned: "planned",
  Auto: "auto",
};

export const campaignTypeMapping = {
  [CampaignTypes.Flash]: {
    checkIsDeletable: () => true,
    statusText: () => i18next.t("text.campaignDetails.sent", "Sent"),
    button: {
      color: "primary",
      sign: () => i18next.t("form:button.send", "Send"),
    },
  },
  [CampaignTypes.Planned]: {
    checkIsDeletable: (is_draft) => is_draft,
    statusText: ({ sent_at }) =>
      sent_at ? i18next.t("text.campaignDetails.sent", "Sent") : i18next.t("text.campaignDetails.planned", "Sheduled"),
    button: {
      color: "success",
      sign: () => i18next.t("form:button.schedule", "Schedule"),
    },
  },
  [CampaignTypes.Auto]: {
    checkIsDeletable: () => false,
    statusText: ({ is_active }) =>
      is_active
        ? i18next.t("text.campaignDetails.active", "Active")
        : i18next.t("text.campaignDetails.paused", "Paused"),
    button: {
      color: "success",
      sign: () => i18next.t("form:button.launch", "Launch"),
    },
  },
};
