import i18next from "../../services/language/i18next";
import { CampaignTypes, campaignTypeMapping } from "../../models/Campaign.model";

const getTableColumnHeaders = () => [
  { key: "name", label: i18next.t("table:header.audiences", "Audience Name"), _style: { width: "70%" } },
  { key: "totalSubscribers", label: i18next.t("table:header.subscribers", "Subscribers"), _style: { width: "20%" } },
  { key: "remove", label: "", _style: { width: "10%" } },
];

const getItemsPerPageSelectValues = () => ({
  label: i18next.t("table:itemsAmount", "items per page:"),
  values: [10, 50, 100],
});

const getTableFilterText = () => ({
  label: i18next.t("table:filter.label"),
  placeholder: i18next.t("table:filter.placeholder.audiences", "Search for audiences"),
  external: true,
});

const getCampaignList = (id, campaings) => {
  const campaingsWithType = campaings.map((item) => {
    const type = item.timeout ? CampaignTypes.Auto : item.planned_at ? CampaignTypes.Planned : CampaignTypes.Flash;
    return { ...item, type };
  });

  return campaingsWithType.filter((item) =>
    item.audience !== id ? false : !campaignTypeMapping[item.type].checkIsDeletable(item.is_draft)
  );
};

export { getTableColumnHeaders, getItemsPerPageSelectValues, getTableFilterText, getCampaignList };
