import React, { useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CInput,
  CCardFooter,
  CInvalidFeedback,
  CCardBody,
  CFormGroup,
  CCol,
  CButton,
  CLabel,
  CForm,
  CSwitch,
} from "@coreui/react";
import { withErrorBoundary } from "../../../components/ErrorBoundary";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { showModalWindow } from "../../../utils/actionWrappers";
import { useQuery } from "../../../utils/useQuery";
import { emailCharactersPattern } from "../../../utils";
import { useTranslation, Trans } from "react-i18next";

const MemberAdd = ({ name, onSave, initialData, isMemberExist, onRemove, isMemberEditable = false }) => {
  const query = useQuery();
  const redirect = query.get("redirect");
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    profile.resetForm(
      {
        values: {
          first_name: initialData.first_name || "",
          last_name: initialData.last_name || "",
          email: initialData.email || "",
          password: "",
          confirmPassword: "",
          sendEmail: false,
        },
      },
      [initialData]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const profile = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      sendEmail: false,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      email: yup.string().required().email().matches(emailCharactersPattern),
      password: yup.string(),
      confirmPassword: yup.string().test("confirmPassword", (value, context) => context.parent.password === value),
    }),
    onSubmit: ({ first_name, last_name, password, email, sendEmail }) => {
      onSave({
        first_name,
        last_name,
        send_mail: sendEmail ? 1 : 0,
        email,
        ...(password && { password: password }),
      });
    },
  });

  const onFormReset = () => {
    if (profile.dirty) {
      showModalWindow.confirm(
        t("modal:title.confirm", "Confirm your action"),
        t("modal:cancel", "Are you sure you want to cancel all changes?"),
        "warning",
        () => {
          history.push(redirect || "/members");
        }
      );
    } else {
      history.push(redirect || "/members");
    }
  };

  return (
    <CCard className="MembersCard">
      <CCardHeader>
        <h1>{name}</h1>
      </CCardHeader>
      <CCardBody>
        <CForm id="profile-form" onSubmit={profile.handleSubmit}>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="fist_name">{t("form:label.firstName", "First name")}</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="first_name"
                name="first_name"
                placeholder={t("form:placeholder.firstName", "Enter first name")}
                value={profile.values.first_name}
                onChange={profile.handleChange}
                invalid={!!profile.errors.first_name}
                disabled={!isMemberEditable}
              />
              <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="fist_name">{t("form:label.lastName", "Last name")}</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="last_name"
                name="last_name"
                placeholder={t("form:placeholder.lastName", "Enter last name")}
                value={profile.values.last_name}
                onChange={profile.handleChange}
                invalid={!!profile.errors.last_name}
                disabled={!isMemberEditable}
              />
              <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="email">{t("form:label.email", "Email")}</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                type="email"
                id="email"
                name="email"
                placeholder={t("form:placeholder.email", "Enter email")}
                pattern={emailCharactersPattern}
                autoComplete="email"
                value={profile.values.email}
                onChange={profile.handleChange}
                disabled={!isMemberEditable}
                invalid={!!profile.errors.email}
              />
              <CInvalidFeedback>
                <Trans i18nKey="form:error.email.value">
                  You must enter your email in the format <b>user@domain.com</b>!
                </Trans>
              </CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="password">{t("form:label.password", "Password")}</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                type="password"
                id="password"
                name="password"
                placeholder={t("form:placeholder.password", "Enter password")}
                autoComplete="off"
                value={profile.values.password}
                onChange={profile.handleChange}
                invalid={!!profile.errors.password}
                disabled={!isMemberEditable}
              />
              <CInvalidFeedback>{t("form:error.password.memberPassword", "You must enter password!")}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel htmlFor="confirm-password">{t("form:label.password_confirm", "Confirm password")}</CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder={t("form:placeholder.password_confirm", "Confirm password")}
                autoComplete="off"
                value={profile.values.confirmPassword}
                onChange={profile.handleChange}
                invalid={!!profile.errors.confirmPassword}
                disabled={!isMemberEditable}
              />
              <CInvalidFeedback>{t("form:error.password.match", "Passwords must match!")}</CInvalidFeedback>
            </CCol>
          </CFormGroup>
          <CFormGroup>
            <CSwitch
              size="sm"
              className="mr-3 text-center align-middle"
              color="primary"
              checked={profile.values.sendEmail}
              onChange={profile.handleChange}
              id="sendEmail"
              shape="pill"
              name="sendEmail"
              variant="3d"
            />
            <CLabel className="mb-0" htmlFor="sendEmail">
              {t("form:label.sendEmail", "Send invitational letter")}
            </CLabel>
          </CFormGroup>
        </CForm>
      </CCardBody>
      {
        <CCardFooter>
          {isMemberExist && isMemberEditable && (
            <CButton
              onClick={onRemove}
              type="reset"
              form="profile-form"
              color="danger"
              variant="outline"
              className="mr-3"
            >
              {t("form:button.remove", "Remove")}
            </CButton>
          )}

          <CButton
            onClick={onFormReset}
            type="reset"
            form="profile-form"
            color="warning"
            variant="outline"
            className="mr-3"
          >
            {isMemberEditable ? t("form:button.cancel", "Cancel") : t("form:button.close", "Close")}
          </CButton>
          {isMemberEditable && (
            <CButton type="submit" form="profile-form" color="success" variant="outline">
              {t("form:button.save", "Save")}
            </CButton>
          )}
        </CCardFooter>
      }
    </CCard>
  );
};

export default withErrorBoundary(MemberAdd);
