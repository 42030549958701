import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {store} from "./store";
import {Provider} from "react-redux";
import history from "./history";
import {Router} from "react-router";
import * as Sentry from "@sentry/react";
import config from "./configs";
import "./services/language/i18next";

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (!error.ignore) {
        return event;
      }
    }
  });
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App/>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
