/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from "react";
import {withErrorBoundary} from "../ErrorBoundary";

/**
 * CCreateElement без оптимизации через JSON.stringify.
 *
 * @param {Array} items - Массив объектов для генерации.
 * @param {Object} [components] - Компоненты, необходимые для генерации items.
 * @returns {React.JSX}
 * @constructor
 */
const ARCreateElement = ({items, components}) => {
  const renderItem = (item, i) => {
    const {_tag, _children, ...rest} = item;
    const Tag = components[_tag] || _tag;
    const children = _children ? _children.map((child, i) => {
      return typeof child === "object" ? renderItem(child, i) : child;
    }) : "";
    return <Tag key={Tag + i} {...rest}>{children}</Tag>;
  };

  const generatedItems = useMemo(() => {
    return items && items.map((item, i) => renderItem(item, i));
  }, [items]);

  return (
    <React.Fragment>
      {generatedItems}
    </React.Fragment>
  );
};

ARCreateElement.defaultProps = {
  components: {}
};

export default withErrorBoundary(ARCreateElement);