import LoginSub from "./pages/loginsub";
import ForgotPassword from "./pages/forgotpassword";
import SignUp from "./pages/signup";
import SignUpUser from "./pages/signupuser";
import Login from "./pages/login";
import Profile from "./pages/profile";
import NewPassword from "./pages/newpassword";
import Error from "./pages/error";
import Audiences from "./pages/audiences";
import AudienceExact from "./pages/audienceExact";
import Campaigns from "./pages/campaigns";
import CampaignDetails from "./pages/campaign";
import CampaignEdit from "./pages/campaign/CampaignEdit";
import Members from "./pages/members";
import MemberAdd from "./pages/members/add";
import MemberDetails from "./pages/members/details";
import Directories from "./pages/directories";
import DirectoryAdd from "./pages/directories/Directory/DirectoryAdd";
import DirectoryEdit from "./pages/directories/Directory/DirectoryEdit";
import Settings from "./pages/settings";
import FAQ from "./pages/faq";

const routeNetwork = {
  getRoutes(state) {
    if (!state.global.tenant.error || state.global.tenant.error.status === 404) {
      if (!state.global.tenant.isExist) {
        return [{
          path: "/error", exact: true, name: `Error - ${state.global.tenant.error.status}`,
          default: true, component: Error, props: {error: state.global.tenant.error}
        }];
      }

      if (state.global.tenant.isPublicTenant) {
        return this.publicTenantRoutes;
      }

      if (state.auth.isAuthorized) {
        return this.authorizedRoutes.filter((route) =>
          route.forStaffOnly ? route.forStaffOnly === state.auth.isStaff : true
        );
      }

      return this.unAuthorizedRoutes;
    }

    return [{
      path: "/error", exact: true, name: `Error - ${state.global.tenant.error.status}`,
      default: true, component: Error, props: {error: state.global.tenant.error}
    }];
  },

  authorizedRoutes: [
    {path: "/members", exact: true, name: "page.members", component: Members, forStaffOnly: true},
    {path: "/members/:id(\\d+)", exact: true, name: "page.member.details", component: MemberDetails, forStaffOnly: true},
    {path: "/members/add", exact: true, name: "page.member.add", component: MemberAdd, forStaffOnly: true},
    {path: "/directories", exact: true, name: "page.directories", component: Directories, forStaffOnly: true},
    {path: "/directories/add", exact: true, name: "page.directory.add",  component: DirectoryAdd, forStaffOnly: true},
    {path: "/directories/:id(\\d+)", exact: true, name: "page.directory.edit",  component: DirectoryEdit, forStaffOnly: true},
    {path: "/rules", exact: true, name: "page.rules"},
    {path: "/audiences", exact: true, name: "page.audiences", component: Audiences, default: true},
    {path: "/audience/:id", exact: true, name: "page.audienceDetails", component: AudienceExact},
    {path: "/profile", exact: true, name: "page.profile", component: Profile},
    {path: "/campaigns", exact: true, name: "page.campaigns", component: Campaigns},
    {path: "/campaigns/:id(\\d+)/details", exact: false, name: "page.campaign.details", component: CampaignDetails},
    {path: "/campaigns/:id(\\d+)/edit", exact: true, name: "page.campaign.edit", component: CampaignEdit},
    {path: "/faq", exact: true, name: "page.faq", component: FAQ},
    {path: "/settings", exact: true, name: "page.settings", component: Settings},
  ],

  unAuthorizedRoutes: [
    {path: "/sessions/new-password", exact: false, name: "New Password", component: NewPassword},
    {path: "/forgot_password", exact: true, name: "Forgot Password", component: ForgotPassword},
    {path: "/sign_up_user", exact: true, name: "Sign Up", component: SignUpUser},
    {path: "/login", exact: true, name: "Login", default: true, component: Login},
  ],

  publicTenantRoutes: [
    {path: "/login_sub", exact: true, name: "Login by Subdomain", default: true, component: LoginSub},
    {path: "/sign_up", exact: true, name: "Sign Up with Domain", component: SignUp},
  ],
};

export default routeNetwork;