import React from "react";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";
import SettingsSenders from "./SettingsSenders";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <CCard>
      <CCardHeader>
        <h1>{t("page.settings", "Settings")}</h1>
      </CCardHeader>
      <CCardBody>
        <SettingsSenders />
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(Settings);
