import React from "react";
import { Link } from "react-router-dom";
import "./NewPassword.scss";
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CFormGroup,
  CInvalidFeedback,
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import { newPasswordRequest } from "../../services/api";
import { useQuery } from "../../utils/useQuery";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import { useTranslation } from "react-i18next";

const NewPassword = () => {
  const { t } = useTranslation();
  const [passwordState, setPasswordState] = React.useState({
    password: "",
    confirm_password: "",
    isValid: null,
  });

  const onInputHandler = ({ target }) => {
    setPasswordState((oldState) => {
      const newPasswordState = {
        ...oldState,
        [target.name]: target.value,
      };

      const hasValue = newPasswordState.password || newPasswordState.confirm_password;
      const isEqual = newPasswordState.password === newPasswordState.confirm_password;
      newPasswordState.isValid = hasValue ? isEqual : null;

      return newPasswordState;
    });
  };

  const query = useQuery();
  const onFormSubmit = (event) => {
    event.preventDefault();
    const email = query.get("email");
    const token = query.get("token");

    if (passwordState.isValid) {
      newPasswordRequest(email, token, passwordState.password);
    }
  };

  return (
    <>
      <ARIcon name={"cilBolt"} size={"3xl"} />
      <CForm onSubmit={onFormSubmit}>
        <p className="text-muted">{t("card.description.restorePassword.new", "Enter new password")}</p>

        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilLockLocked"} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            value={passwordState.password}
            onInput={onInputHandler}
            placeholder={t("form:label.password_new", "New password")}
            name="password"
            id="password"
            required={true}
            invalid={passwordState.isValid === false}
          />
        </CInputGroup>

        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <ARIcon name={"cilLockLocked"} />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            placeholder={t("form:label.password_newConfirm", "Confirm new password")}
            value={passwordState.confirm_password}
            onInput={onInputHandler}
            name="confirm_password"
            id="confirm_password"
            required={true}
            invalid={passwordState.isValid === false}
          />
          <CInvalidFeedback>{t("form:error.password.match", "Passwords must match!")}</CInvalidFeedback>
        </CInputGroup>

        <CFormGroup>
          <CButton className="w-100" type="submit" color="primary">
            {t("button.send", "Send")}
          </CButton>
        </CFormGroup>

        <CFormGroup className="w-100 text-center" row>
          <CCol>
            <Link to="/login">{t("button.login", "Sign In")}</Link>
          </CCol>
          <CCol>
            <Link to="/sign_up_user">{t("button.registerNewAccount", "Create a new account")}</Link>
          </CCol>
        </CFormGroup>
      </CForm>
    </>
  );
};

export default withErrorBoundary(NewPassword);
