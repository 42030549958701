import React from "react";
import * as Sentry from "@sentry/react";
import FallbackStub from "./FallbackStub";


const ErrorBoundary = (props) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackStub}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export const withErrorBoundary = (Component) => Sentry.withErrorBoundary(Component, {fallback: FallbackStub});

export default ErrorBoundary;
