import React from "react";
import { useHistory } from "react-router-dom";
import { createMemberDirectoryRequest } from "../../../../services/api";
import { showNotification } from "../../../../utils/actionWrappers";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";
import Directory from "../Directory";
import { useTranslation } from "react-i18next";

const DirectoryAdd = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const createDirectory = (values) => {
    createMemberDirectoryRequest(values).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.added.directory", " Directory has been successfully added"),
        "success"
      );
      history.push("/directories");
    });
  };

  return <Directory name={t("card.title.directory.new","New directory")} onSave={createDirectory} initialData={{}}  />;
};

export default withErrorBoundary(DirectoryAdd);
