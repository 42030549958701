import {GLOBAL} from "../actions/types";

const initialState = {
  isFetching: false,
  requestsNum: 0,
  tenant: {
    isPublicTenant: null,
    isExist: null,
    error: null,
    hasAdmin: null
  }
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL.FETCHING:
      return {
        ...state,
        isFetching: true,
        requestsNum: state.requestsNum + 1
      };
    case GLOBAL.FETCHED:
      return {
        ...state,
        isFetching: false,
        requestsNum: state.requestsNum - 1
      };
    case GLOBAL.SET_TENANT:
      return {
        ...state,
        tenant: action.payload
      };
    case GLOBAL.SET_HAS_ADMIN:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          hasAdmin: action.payload.hasAdmin
        }
      };
    default:
      return state;
  }
};

export default globalReducer;