import React, {useMemo} from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import preval from "preval.macro";
import "./Sidebar.scss";
import {useSelector} from "react-redux";

// sidebar nav config
import getNavigation from "./navigation";
import Logo from "../../../Logo";
import {useTranslation} from "react-i18next";
import ARCreateElement from "../../../ARCreateElement/ARCreateElement";
import {getTime, getDate} from "../../../../utils";

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;

const Sidebar = (props) => {
  const {i18n, t} = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const {isStaff} = useSelector(state => state.auth);
  const navigation = useMemo(() => getNavigation(isStaff), [i18n.language]);

  return (
    <CSidebar
      show={props.showSidebar}
      onShowChange={(val) => props.setShowSidebar(val)}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <Logo
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
          />
        <Logo
        className="c-sidebar-brand-minimized"
        name="sygnet"
        height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <ARCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="sidebarMinimizer  c-d-md-down-none">
        <span>{t("sidebar.currentBuild", "Current build:")} {`${getDate(dateTimeStamp)} ${getTime(dateTimeStamp)}`}</span>
      </CSidebarMinimizer>
    </CSidebar>
  );
};

export default React.memo(Sidebar);
