import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import * as Sentry from "@sentry/react";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer));
