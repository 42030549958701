import React from "react";
import {
  CButton,
  CInputGroup,
} from "@coreui/react";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {useTranslation} from "react-i18next";

const Error = (props) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="clearfix">
        <h1 className="float-left display-3 mr-4">{props.error.status}</h1>
        <h4 className="pt-3">{t("card.title.error", "Houston, we have a problem!")}</h4>
        <p className="text-muted float-left">{props.error.statusText}</p>
      </div>
      <CInputGroup className="input-prepend">
        <CButton color="info" block size="lg" onClick={() => window.history.back()}>{t("button.back", "Back")}</CButton>
      </CInputGroup>
    </>
  );
};

export default withErrorBoundary(Error);