import React from "react";
import { withErrorBoundary } from "../../../components/ErrorBoundary";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";
import { createMembersRequest } from "../../../services/api";
import { showNotification } from "../../../utils/actionWrappers";
import Member from "../Member";
import { useTranslation } from "react-i18next";

const MemberAdd = () => {
  const query = useQuery();
  const redirect = query.get("redirect");
  const history = useHistory();
  const { t } = useTranslation();

  const createMember = (values) => {
    createMembersRequest(values).then(() => {
      showNotification(
        t("notification:title.success", "Success!"),
        t("notification:message.added.member", "Member has been successfully added!"),
        "success"
      );
      history.push(redirect || "/members");
    });
  };

  return <Member isMemberExist={false} name={t("page.member.add","Add member")} onSave={createMember} initialData={{}} isMemberEditable={true} />;
};

export default withErrorBoundary(MemberAdd);
