import {NOTIFICATION} from "../actions/types";

export {AUTH} from "../actions/types";


const initialState = {
  title: "",
  message: "",
  isShowing: false,
  type: "info"
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.SHOW:
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
        isShowing: true,
      };
    case NOTIFICATION.CLEAR:
      return {
        ...state,
        title: "",
        message: "",
        type: "info",
        isShowing: false
      };
    case NOTIFICATION.HIDE:
      return {
        ...state,
        isShowing: false
      };
    default:
      return state;
  }
};

export default notificationReducer;