import React, {useState, useEffect, useRef} from "react";
import "./Spinner.scss";

const Spinner = ({fullScreen, show, timeout, requestsNum}) => {
  const [showSpinner, setShowSpinner] = useState(show);
  const hideTimeoutRef = useRef(null);

  useEffect(() => {
    if (show) {
      clearTimeout(hideTimeoutRef.current);
      setShowSpinner(true);
    } else if (requestsNum === undefined || requestsNum <= 0) {
      hideTimeoutRef.current = setTimeout(() => setShowSpinner(false), Number(timeout) || 100);
    }
  }, [requestsNum, show]);

  if (!showSpinner) {
    return null;
  }

  return (
    <div className={fullScreen ? "Spinner-container Spinner-container_fullscreen" : "Spinner-container"}>
      <div className="Spinner"/>
    </div>
  );
};

Spinner.defaultProps = {
  timeout: 100
};

export default Spinner;