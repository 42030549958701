import React, { useState, useMemo } from "react";
import "./../Directories.scss";
import { CButton, CCol, CTooltip, CListGroupItem } from "@coreui/react";
import {
  testMemberDirectoryConnectionRequest,
  deleteMemberDirectoryRequest,
  updateMemberDirectoryRequest,
  readMemberDirectoryRequest,
} from "../../../services/api";
import { useHistory } from "react-router-dom";
import { showModalWindow } from "../../../utils/actionWrappers";
import { withErrorBoundary } from "../../../components/ErrorBoundary";
import { useTranslation, Trans } from "react-i18next";

const DirectoryCard = ({
  directoriesInterval,
  setDirectoriesInterval,
  directoryData,
  onDeleteDirectorySuccess,
  updateDirectoriesAndHandleInterval,
}) => {
  const [directoryInfo, setDirectoryInfo] = useState({ ...directoryData });
  const history = useHistory();
  const { t } = useTranslation();

  useMemo(() => {
    setDirectoryInfo({ ...directoryData });
  }, [directoryData]);

  const onEditClick = () => {
    history.push(`/directories/${directoryInfo.id}`);
  };

  const onRemoveDirectoryClick = () => {
    showModalWindow.confirm(
      t("modal:title.confirm", "Confirm your action"),
      t(
        "modal:message.delete.directory_withItemName",
        `Are you sure you want to delete the directory ${directoryInfo.name}?`,
        { itemName: directoryInfo.name }
      ),
      "danger",
      () => {
        deleteMemberDirectoryRequest(directoryInfo.id).then(() => onDeleteDirectorySuccess());
      }
    );
  };

  const onTestClick = () => {
    testMemberDirectoryConnectionRequest(directoryInfo.id).then(
      () =>
        showModalWindow.alert(
          t("notification:title.success", "Success!"),
          t("notification:message.connection.success", "Connection successful"),
          "success"
        ),
      (error) => {
        if (error.response.status === 400) {
          showModalWindow.alert(t("notification:title.error", "Error!"), `${error.response.data.msg}`, "danger");
        }
      }
    );
  };

  const onChangeAutoSyncStatusClick = () => {
    updateMemberDirectoryRequest(directoryInfo.id, { auto_sync: !directoryInfo.auto_sync }).then(() =>
      setDirectoryInfo((oldState) => ({
        ...oldState,
        auto_sync: !oldState.auto_sync,
      }))
    );
  };

  const onSyncClick = () => {
    clearInterval(directoriesInterval);
    const requestInterval = setInterval(() => updateDirectoriesAndHandleInterval(requestInterval), 10000);
    setDirectoriesInterval(requestInterval);

    updateMemberDirectoryRequest(directoryInfo.id, { sync_now: true }).then(({ data }) => {
      setDirectoryInfo((oldState) => ({
        ...oldState,
        is_syncing_now: data.is_syncing_now,
      }));
    });
  };

  const onRefreshClick = () => {
    readMemberDirectoryRequest(directoryInfo.id).then(({ data }) => setDirectoryInfo(data));
  };

  return (
    <CListGroupItem action className="Directory-Card text-md-left text-center d-flex flex-md-row flex-column">
      <CCol xl="3" lg="3" md="3" className="Directory-Card__info mt-2 mb-1 mr-1">
        <span className="mr-0 mr-md-4">{directoryInfo.name}</span>
        <span>{directoryInfo.type}</span>
      </CCol>
      <CCol xl="9" lg="9" md="9" className="text-md-right qwe">
        <CButton
          onClick={onChangeAutoSyncStatusClick}
          className="Directory-Card__disable-enable-button mb-1 mr-1"
          variant="outline"
          color="primary"
        >
          {directoryInfo.auto_sync ? t("button.disable", "Disable") : t("button.enable", "Enable")}
        </CButton>
        <CButton
          onClick={onRemoveDirectoryClick}
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
          disabled={directoryInfo.isSyncingNow}
        >
          {t("button.delete", "Delete")}
        </CButton>
        <CButton
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
          onClick={onEditClick}
          disabled={directoryInfo.isSyncingNow}
        >
          {t("button.edit", "Edit")}
        </CButton>
        <CButton onClick={onTestClick} className="mb-1 mr-1" variant="outline" color="primary">
          {t("button.test", "Test")}
        </CButton>
        <CButton
          onClick={() => (directoryInfo.is_syncing_now ? onRefreshClick() : onSyncClick())}
          className="mb-1 mr-1"
          variant="outline"
          color="primary"
        >
          {directoryInfo.is_syncing_now ? t("button.refresh", "Refresh") : t("button.synchronise", "Synchronise")}
        </CButton>
        <div className="text-right mr-1">
          {directoryInfo.isSyncingNow
            ? t("synchronizing.pending", "Syncing...")
            : (directoryInfo.lastError && (
                <CTooltip content={directoryInfo.lastError} placement="bottom">
                  <span className="text-danger">{t("synchronizing.rejected", "Sync Error!")}</span>
                </CTooltip>
              )) ||
              (directoryInfo.synced && (
                <Trans i18nKey="synchronizing.fulfilled" values={{ date: directoryInfo.synced }}>
                  Last sync at {directoryInfo.synced}
                </Trans>
              ))}
        </div>
      </CCol>
    </CListGroupItem>
  );
};

export default withErrorBoundary(DirectoryCard);
