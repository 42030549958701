import React from "react";
import { CButton, CLabel, CCol, CInput, CRow, CForm, CInvalidFeedback } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { Trans } from "react-i18next";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { createSendersRequest } from "../../../services/api/index";

const AddSenderForm = ({ setSenders }) => {
  const { t } = useTranslation();

  const sender = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validateOnChange: false,
    validationSchema: yup.object({
      name: yup.string().required(t("form:error.required", "This field is required!")),
      email: yup
        .string()
        .required(t("form:error.required", "This field is required!"))
        .email(
          <Trans i18nKey="form:error.email.value">
            You must enter your email in the format <b>user@domain.com</b>!
          </Trans>
        ),
    }),
    onSubmit: async (values, {resetForm}) => {
      const { data } = await createSendersRequest(values);
      setSenders((state) => [...state, data]);
      resetForm({});
    },
  });

  return (
    <CForm onSubmit={sender.handleSubmit}>
      <CRow>
        <CCol>
          <CLabel>{t("form:label.addSender", "Add sender")}</CLabel>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CInput
            type="text"
            id="name"
            name="name"
            autoComplete="name"
            placeholder={t("form:placeholder.senderName", "Enter sender name")}
            onChange={sender.handleChange}
            value={sender.values.name}
            invalid={!!sender.errors.name}
          />
          <CInvalidFeedback>{sender.errors.name}</CInvalidFeedback>
        </CCol>
        <CCol>
          <CInput
            type="email"
            id="email"
            name="email"
            autoComplete="email"
            placeholder={t("form:placeholder.email", "Enter email")}
            onChange={sender.handleChange}
            invalid={!!sender.errors.email}
            value={sender.values.email}
          />
          <CInvalidFeedback>{sender.errors.email}</CInvalidFeedback>
        </CCol>
        <CCol>
          <CButton type="submit" onClick={sender.handleSubmit} color="primary">
            {t("form:button.addSender", "Add sender")}
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

export default withErrorBoundary(AddSenderForm);
