import React, { useState } from "react";
import "./CampaignDetails.scss";
import { CButton, CCol, CRow, CSelect, CInput } from "@coreui/react";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

const CampaignChangeTimeoutFrom = ({ timeout, updateTimeout }) => {
  const { t } = useTranslation();

  const [isFormEditable, setIsFormEditable] = useState(false);

  const automailForm = useFormik({
    initialValues: {
      timeoutValue: timeout.timeoutValue,
      timeoutUnits: timeout.timeoutUnits,
    },
    onSubmit: (values) => {
      setIsFormEditable(false);
      updateTimeout(values);
    },
  });

  const handleBlur = () => {
    if (automailForm.values.timeoutValue < 0) {
      automailForm.resetForm({
        values: {
          ...automailForm.values,
          timeoutValue: "5",
          timeoutUnits: "minutes",
        },
      });
    }
  };

  return (
    <CRow>
      <CCol className="campaign__timeoutFormElement" md="6" lg>
        <CInput
          name="timeoutValue"
          id="timeoutValue"
          onChange={automailForm.handleChange}
          value={automailForm.values.timeoutValue}
          type="number"
          disabled={!isFormEditable}
          onBlur={handleBlur}
        />
      </CCol>
      <CCol className="campaign__timeoutFormElement" md="6" lg>
        <CSelect
          name="timeoutUnits"
          id="timeoutUnits"
          onChange={automailForm.handleChange}
          value={automailForm.values.timeoutUnits}
          disabled={!isFormEditable}
        >
          <option value="minutes">{t("form:placeholder.minutes", "Minutes")}</option>
          <option value="hours">{t("form:placeholder.hours", "Hours")}</option>
          <option value="days">{t("form:placeholder.days", "Days")}</option>
        </CSelect>
      </CCol>
      <CCol className="campaign__timeoutFormElement" md="12" lg>
        {isFormEditable ? (
          <CButton onClick={automailForm.handleSubmit} color="success">
            {t("form:button.save", "Save")}
          </CButton>
        ) : (
          <CButton onClick={() => setIsFormEditable(true)} color="primary">
            {t("form:button.edit", "Edit")}
          </CButton>
        )}
      </CCol>
    </CRow>
  );
};

export default withErrorBoundary(CampaignChangeTimeoutFrom);
