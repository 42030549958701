import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel
} from "@coreui/react";
import ARIcon from "../../components/ARIcon";
import "../signup/SignUp.scss";
import {createFormInitialState, emailCharactersPattern, emailValidation} from "../../utils";
import {userSignUpRequest} from "../../services/api";
import {useActions} from "../../store/actions";
import {loginAction} from "../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import {setHasAdminAction} from "../../store/actions/global";
import {signUpUserFields} from "./SignUpUser.model";
import { withErrorBoundary } from "../../components/ErrorBoundary";
import {Trans, useTranslation} from "react-i18next";


const SignUpUser = () => {
    const [credentials, setCredentials] = useState(createFormInitialState(signUpUserFields));
    const [isRememberMe, setIsRememberMe] = useState(false);
    const [isServiceAgree, setIsServiceAgree] = useState({
      checked: false,
      isValid: null
    });

    const hasAdmin = useSelector(state => state.global.tenant.hasAdmin);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onCredentialsChange = useCallback((event) => {
      if (!event.target.validity.patternMismatch) {
        let isValid = null;
        switch (event.target.name) {
          case "email":
            isValid = emailValidation.test(event.target.value);
            break;
          case "confirm_password":
            isValid = !!credentials.password ? credentials.password.value === event.target.value : null;
            break;
          default:
            isValid = !!event.target.value;
            break;
        }

        setCredentials((oldState) => ({
            ...oldState,
            [event.target.name]: {
              value: event.target.value,
              isValid: isValid,
              showValidationRules: false
            }
          })
        );
      } else {
        setCredentials((oldState) => ({
            ...oldState,
            [event.target.name]: {
              ...oldState[event.target.name],
              showValidationRules: true
            }
          })
        );
      }
    }, [credentials.password]);

    const onIsServiceAgreeChange = useCallback((event) => {
      setIsServiceAgree({
        checked: event.target.checked,
        isValid: event.target.checked
      });
    }, []);

    const login = useActions(loginAction);

    const isValidForm = Object.values(credentials).reduce((isValid, field) => isValid && !!field.isValid, true);

    const onFormSubmit = useCallback((event) => {
      event.preventDefault();

      if (!isServiceAgree.checked) {
        setIsServiceAgree((oldState) => ({
            ...oldState,
            isValid: false
          })
        );
      } else if (isValidForm) {
        userSignUpRequest(
          Object.keys(credentials).reduce((data, fieldName) => {
            data[fieldName] = credentials[fieldName].value;
            return data;
          }, {}))
          .then(
            () => {
              login(credentials.email.value, credentials.password.value)
                .then(
                  () => dispatch(setHasAdminAction(true))
                );
            }
          );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidForm, isServiceAgree.checked]);

    return (
      <>
        {hasAdmin ? (
          <>
            <ARIcon name={"cilBolt"} size={"3xl"} />
            <h1>{t("card.title.register", "Lets Start")}</h1>
            <p className="text-muted">
              {t(
                "card.description.hasAdmin",
                "Please, contact domain administrator to get your account information before using the service"
              )}
            </p>

            <CFormGroup className="w-100 text-center">
              <Link to="/login">{t("button.loginToExistingUser", "Sign in to existing account")}</Link>
            </CFormGroup>
          </>
        ) : (
          <>
            <ARIcon name={"cilBolt"} size={"3xl"} />
            <CForm onSubmit={onFormSubmit}>
              <h1>{t("card.title.register", "Lets Start")}</h1>
              <p className="text-muted">{t("card.description.register", "Register your company to use the service")}</p>

              <CInputGroup className="mb-4">
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.firstName", "First name")}</CInputGroupText>
                </CInputGroupPrepend>

                <CInput
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={credentials.first_name.value}
                  onChange={onCredentialsChange}
                  valid={credentials.first_name.isValid}
                  invalid={credentials.first_name.isValid === false}
                  placeholder={t("form:placeholder.firstName", "Enter first name")}
                  autoComplete="first_name"
                />
                <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
              </CInputGroup>

              <CInputGroup className="mb-4">
                <CInputGroupPrepend>
                  <CInputGroupText>{t("form:label.lastName", "Last name")}</CInputGroupText>
                </CInputGroupPrepend>

                <CInput
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={credentials.last_name.value}
                  onChange={onCredentialsChange}
                  valid={credentials.last_name.isValid}
                  invalid={credentials.last_name.isValid === false}
                  placeholder={t("form:placeholder.lastName", "Enter last name")}
                  autoComplete="last_name"
                />
                <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
              </CInputGroup>

              <CInputGroup className="mb-4">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <ARIcon name={"cilLink"} />
                  </CInputGroupText>
                </CInputGroupPrepend>

                <CInput
                  type="text"
                  id="email"
                  name="email"
                  value={credentials.email.value}
                  onChange={onCredentialsChange}
                  valid={credentials.email.isValid}
                  invalid={credentials.email.isValid === false}
                  pattern={emailCharactersPattern}
                  required
                  placeholder={t("form:placeholder.email", "Enter email")}
                  autoComplete="email"
                />
                {(credentials.email.showValidationRules || credentials.email.isValid === false) && (
                  <div className="invalid-feedback invalid-feedback_show">
                    <Trans i18nKey="form:error.email.value">
                      You must enter your email in the format <b>user@domain.com</b>!
                    </Trans>
                  </div>
                )}
              </CInputGroup>

              <CInputGroup className="mb-4">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <ARIcon name={"cilLockLocked"} />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="password"
                  value={credentials.password.value}
                  onChange={onCredentialsChange}
                  valid={credentials.password.isValid}
                  invalid={credentials.password.isValid === false}
                  placeholder={t("form:placeholder.password", "Enter password")}
                  name="password"
                  id="password"
                  required
                />
                <CInvalidFeedback>{t("form:error.required", "This field is required!")}</CInvalidFeedback>
              </CInputGroup>

              <CInputGroup className="mb-4">
                <CInputGroupPrepend>
                  <CInputGroupText>
                    <ARIcon name={"cilLockLocked"} />
                  </CInputGroupText>
                </CInputGroupPrepend>
                <CInput
                  type="password"
                  placeholder={t("form:placeholder.password_confirm", "Confirm password")}
                  value={credentials.confirm_password.value}
                  onChange={onCredentialsChange}
                  valid={credentials.confirm_password.isValid}
                  invalid={credentials.confirm_password.isValid === false}
                  name="confirm_password"
                  id="confirm_password"
                  required
                />
                <CInvalidFeedback>{t("form:error.password.match", "Passwords must match!")}</CInvalidFeedback>
              </CInputGroup>

              <CFormGroup className="ml-4 checkbox">
                <CInputCheckbox
                  id="service_checkbox"
                  checked={isServiceAgree.checked}
                  onChange={onIsServiceAgreeChange}
                  valid={isServiceAgree.isValid}
                  invalid={isServiceAgree.isValid === false}
                />
                <CLabel htmlFor="service_checkbox" className="mb-0">
                  <Trans i18nKey="form:label.terms">
                    I have read and agree to the <Link to="/#">terms of the service</Link>.
                  </Trans>
                </CLabel>
                <CInvalidFeedback>{t("form:error.terms.value", "You must agree to the terms of service!")}</CInvalidFeedback>
              </CFormGroup>

              <CFormGroup className="ml-4 checkbox">
                <CInputCheckbox
                  id="remember_me"
                  checked={isRememberMe}
                  onChange={(event) => setIsRememberMe(event.target.checked)}
                />
                <CLabel htmlFor="remember_me" className="mb-0">
                  {t("form:label.rememberMe", "Remember me")}
                </CLabel>
              </CFormGroup>

              <CFormGroup>
                <CButton className="w-100" type="submit" color="primary" disabled={!isValidForm}>
                  {t("button.register", "Register")}
                </CButton>
              </CFormGroup>

              <CFormGroup className="w-100 text-center">
                <Link to="/login">{t("button.loginToExistingUser", "Sign in to existing account")}</Link>
              </CFormGroup>
            </CForm>
          </>
        )}
      </>
    );
  }
;

export default withErrorBoundary(SignUpUser);