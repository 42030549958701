import React from "react";
import { CCard, CCardBody, CRow, CCol } from "@coreui/react";
import { CChartBar } from "@coreui/react-chartjs";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";

const CampaignStatistics = ({ campaign }) => {
  const { t } = useTranslation();
  const { total_sent, was_opened, unsubscribed } = campaign;

  return (
    <CCard className="statistic__chart">
      <CCardBody>
        <CRow>
          <CCol lg="12" xl="8">
            <CChartBar
              datasets={[
                {
                  backgroundColor: "#35A2EB",
                  data: [total_sent, was_opened, unsubscribed],
                },
              ]}
              labels={[
                t("text.campaignStatistic.totalSent", "Sent"),
                t("text.campaignStatistic.wasOpened", "Opened"),
                t("text.campaignStatistic.unsubscribed", "Unsubscribed"),
              ]}
              options={{
                tooltips: {
                  enabled: true,
                },
                legend: {
                  display: false,
                },
                borderWidth: 50,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(CampaignStatistics);
