import {NOTIFICATION} from "./types";

export function showNotificationAction(title, message, type = "info") {
  return {
    type: NOTIFICATION.SHOW,
    payload: {
      title,
      message,
      type
    }
  };
}

export function clearNotificationAction() {
  return {
    type: NOTIFICATION.CLEAR,
  };
}

export function hideNotificationAction() {
  return {
    type: NOTIFICATION.HIDE,
  };
}