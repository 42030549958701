import React, {useEffect, useState} from "react";
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader} from "@coreui/react";
import {useSelector} from "react-redux";
import {useActions} from "../../store/actions";
import {hideModalWindowAction} from "../../store/actions/modal";
import {useTranslation} from "react-i18next";

const ModalWindow = () => {
  const {title, message, type, isShowing, callbackOK, callbackCancel, color} = useSelector(state => state.modal);
  const [showModalWindow, setShowModalWindow] = useState(isShowing);
  const hideModalWindow = useActions(hideModalWindowAction);
  const {t} = useTranslation();

  const onCloseModalWindow = () => {
    hideModalWindow();
  };

  const onOKButtonClick = () => {
    callbackOK && callbackOK();
    hideModalWindow();
  };

  const onCancelButtonClick = () => {
    callbackCancel && callbackCancel();
    hideModalWindow();
  };

  useEffect(() => {
    setShowModalWindow(isShowing);
  }, [title, message, type, isShowing]);

  return (
    <CModal
      show={showModalWindow}
      onClose={onCloseModalWindow}
      color={color}
      centered
    >
      <CModalHeader closeButton>{title}</CModalHeader>
      <CModalBody>
        {message}
      </CModalBody>
      <CModalFooter>
        <CButton
          color={color}
          onClick={onOKButtonClick}
        >
          {t("button.ok", "OK")}
        </CButton>
        {type === "confirm" &&
        <>
          {" "}
          <CButton
            color="secondary"
            onClick={onCancelButtonClick}
          >
            {t("button.cancel", "Cancel")}
          </CButton>
        </>
        }
      </CModalFooter>
    </CModal>
  );
};

export default ModalWindow;