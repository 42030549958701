import React from "react";
import "./CampaignDetails.scss";
import { CCard, CCardBody, CButton, CCol, CRow, CListGroupItem, CListGroup } from "@coreui/react";
import { withErrorBoundary } from "../../../components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import CampaignChangeTimeoutFrom from "./CampaignChangeTimeoutFrom";
import { getTimeout } from "../../../utils/index";
import CampaignStatus from "./CampaignStatus";
import { CampaignTypes } from "../../../models/Campaign.model";

const CampaignDetails = ({ campaign, updateCampaignAutomailStatues, updateCampaignTimeout }) => {
  const { t } = useTranslation();
  const timeout = getTimeout(campaign.timeout);

  const clickHandler = () => updateCampaignAutomailStatues({ is_active: !campaign.is_active });

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <CListGroup className="campaign__details-list">
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.status", "Status")}</CCol>
                <CCol>
                  <CampaignStatus campaign={campaign} />
                </CCol>
              </CListGroupItem>
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.audience", "Audience")}</CCol>
                <CCol>{campaign.audienceName}</CCol>
              </CListGroupItem>
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.sendersEmail", "Sender's email")}</CCol>
                <CCol>{campaign.sender?.email}</CCol>
              </CListGroupItem>
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.sendersName", "Sender's name")}</CCol>
                <CCol>{campaign.sender?.name}</CCol>
              </CListGroupItem>
              {campaign.type === CampaignTypes.Auto && (
                <CListGroupItem className="d-flex justify-content-between">
                  <CCol>{t("text.campaignDetails.automail", "Send after subscriber is added")}</CCol>
                  <CCol>
                    <CampaignChangeTimeoutFrom timeout={timeout} updateTimeout={updateCampaignTimeout} />
                  </CCol>
                </CListGroupItem>
              )}
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.sentDate", "Sent date")}</CCol>
                <CCol>{campaign.planned_at || campaign.sent_at}</CCol>
              </CListGroupItem>
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.subject", "Subject")}</CCol>
                <CCol>{campaign.subject}</CCol>
              </CListGroupItem>
              <CListGroupItem className="d-flex justify-content-between">
                <CCol>{t("text.campaignDetails.templateName", "Template name")}</CCol>
                <CCol>{campaign.template_name}</CCol>
              </CListGroupItem>
            </CListGroup>
          </CCol>
        </CRow>
        {campaign.type === CampaignTypes.Auto && (
          <CRow>
            <CCol className="pl-5 mt-3">
              {campaign.is_active ? (
                <CButton onClick={clickHandler} className="mb-3 mr-3" color="warning">
                  {t("form:button.pause", "Pause")}
                </CButton>
              ) : (
                <CButton onClick={clickHandler} className="mb-3 mr-3" color="success">
                  {t("form:button.launch", "Launch")}
                </CButton>
              )}
            </CCol>
          </CRow>
        )}
      </CCardBody>
    </CCard>
  );
};

export default withErrorBoundary(CampaignDetails);
